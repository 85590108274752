import React, { lazy, Suspense } from 'react';
import { createBrowserRouter, Route, createRoutesFromElements, Navigate } from 'react-router-dom';
import MainLayout from './layouts/MainLayout';
import NoLayout from './layouts/NoLayout';
import DefaultLoader from './components/defaultLoader';
import { CalendarPage } from './pages/calendarPage';
import CalendarLoader from './components/calendarComponent/calanderLoader';
import InvestigationDiag from './pages/Clinical_Profile/Investigation_Diagnosis/Investigation_diagnosis.index';
import Test from './pages/test';


// Lazy loading components
const Examination = lazy(() => import('./pages/Clinical_Profile/Examination/Examination'));
const Login = lazy(() => import('./pages/login/index'));
const HPI = lazy(() => import('./pages/Clinical_Profile/HPI/Hpi'));
const RegistrationPage = lazy(() => import('./pages/Registration/index'));
const NewRegistrationPage = lazy(() => import('./pages/Registration/NewRegistration/index'));
const PatientPartnerRegistrationPage = lazy(() => import('./pages/Registration/NewRegistration/PatientPartnerRegistration/index'));
const BillingInformationPage = lazy(() => import('./pages/Registration/NewRegistration/BillingInformation/index'));
const PatinetView = lazy(() => import('./pages/patientView/index'));
const ClinicalPatientList = lazy(() => import('./pages/Clinical_Profile/patinetProfile.index'));
const MedicalHistory = lazy(() => import('./pages/Clinical_Profile/MedicalHistory/medicaHistory.index'));
const ViewStockPage = lazy(() => import('./pages/Inventory/index'));
const AddStockPage = lazy(() => import('./pages/Inventory/AddStock/index'));
const ConsumeStockPage = lazy(() => import('./pages/Inventory/ConsumeStock/index'));
const StockTransferPage = lazy(() => import('./pages/StockTransfer/index'));
const IndentPage = lazy(() => import('./pages/StockTransfer/Indent/index'));
const Issued = lazy(() => import('./pages/StockTransfer/Issue/Issued/index'));
const Received = lazy(() => import('./pages/StockTransfer/Receive/Received/index'));
const IssueProspective = lazy(() => import('./pages/StockTransfer/Issue/Prospective/index'));
const ReceiveProspective = lazy(() => import('./pages/StockTransfer/Receive/Prospective/index'));

// Define the routes using createRoutesFromElements
const routes = createBrowserRouter(
  createRoutesFromElements(
    <>
      <Route path='/' element={<Navigate to='/login' replace />} />
      {/* Public Route for Login */}
      <Route
        path='/login'
        element={
          <Suspense fallback={<DefaultLoader />}>
            <NoLayout>
              <Login />
            </NoLayout>
          </Suspense>
        }
      />

      {/* Main Routes with Layout */}
      <Route
        path='/'
        element={<MainLayout />} // MainLayout wraps routes with header and sidebar
      >
        {/* Test Routes */}
        <Route
          path='test'
          element={
            <Suspense fallback={<DefaultLoader />}>
              <Test />
            </Suspense>
          }
          handle={{ breadcrumb: 'Test' }}
        >
          <Route
            path='emp/:id'
            element={
              <Suspense fallback={<DefaultLoader />}>
                <HPI />
              </Suspense>
            }
            handle={{ breadcrumb: ({ id }: { id: string }) => `emp ${id}` }}
          />
        </Route>

        {/* Admin Routes */}
        <Route
          path='admin'
          handle={{ breadcrumb: 'Admin' }}
          hasErrorBoundary
        >
          <Route index element={<Navigate to='appointments/' replace />} />

          <Route
            path='appointments/'
            element={
              <Suspense fallback={<CalendarLoader />}>
                <CalendarPage />
              </Suspense>
            }
            handle={{ breadcrumb: 'Appointments' }}
          />
          <Route
            path='registration/'
            handle={{ breadcrumb: 'Registration' }}
          >
            <Route
              index
              element={
                <Suspense fallback={<DefaultLoader />}>
                  <RegistrationPage />
                </Suspense>
              }
              handle={{ breadcrumb: 'Patient List' }}
            />
            <Route
              path='patient/:id'
              element={
                <Suspense fallback={<DefaultLoader />}>
                  <PatinetView />
                </Suspense>
              }
              handle={{ breadcrumb: ({ id }: { id: string }) => `Patient #${id}` }}
            />
            <Route
              path='patient-list'
              element={
                <Suspense fallback={<DefaultLoader />}>
                  <RegistrationPage />
                </Suspense>
              }
              handle={{ breadcrumb: 'Patient List' }}
            />
            <Route
              path='new-registration'
              element={
                <Suspense fallback={<DefaultLoader />}>
                  <NewRegistrationPage />
                </Suspense>
              }
              handle={{ breadcrumb: 'New Registration' }}
            />
            <Route
              path='patient-partner-registration'
              element={
                <Suspense fallback={<DefaultLoader />}>
                  <PatientPartnerRegistrationPage />
                </Suspense>
              }
              handle={{ breadcrumb: 'New Registration' }}
            />
            <Route
              path='patient-partner-registration-update'
              element={
                <Suspense fallback={<DefaultLoader />}>
                  <PatientPartnerRegistrationPage />
                </Suspense>
              }
              handle={{ breadcrumb: 'Update Patient' }}
            />
            <Route
              path='billing-information'
              element={
                <Suspense fallback={<DefaultLoader />}>
                  <BillingInformationPage />
                </Suspense>
              }
              handle={{ breadcrumb: 'Billing Information' }}
            />
          </Route>
          {/* Inventory Routes */}
          <Route
            path = 'inventory'
            handle={{ breadcrumb: 'Inventory' }}
          >
            <Route
              path='stock'
              handle={{ breadcrumb: 'Stock' }}
            >
              <Route
                index
                element={
                  <Suspense fallback={<DefaultLoader />}>
                    <ViewStockPage />
                  </Suspense>
                }
                handle={{ breadcrumb: 'View' }}
              />
              <Route
                path='add'
                element={
                  <Suspense fallback={<DefaultLoader />}>
                    <AddStockPage />
                  </Suspense>
                }
                handle={{ breadcrumb: 'Add' }}
              />
              <Route
                path='consume'
                element={
                  <Suspense fallback={<DefaultLoader />}>
                    <ConsumeStockPage />
                  </Suspense>
                }
                handle={{ breadcrumb: 'Material Consumption' }}
              />
            </Route>

            <Route
              path = 'stock-transfer'
              handle={{ breadcrumb: 'Stock Transfer' }}
              element={
                <Suspense fallback={<DefaultLoader />}>
                  <StockTransferPage />
                </Suspense>
              }
            >
              <Route
                index
                element={<Navigate to="indent" replace />}
              />
              <Route
                path='indent'
                handle={{ breadcrumb: 'Indent' }}
                element={
                  <Suspense fallback={<DefaultLoader />}>
                    <IndentPage />
                  </Suspense>
                }
              />
              <Route
                path="issue"
                handle={{ breadcrumb: 'Issue' }}
                element={<Navigate to="issue/prospective" replace />}
              >
                <Route
                  path="prospective"
                  handle={{ breadcrumb: 'Prospective' }}
                  element={
                    <Suspense fallback={<DefaultLoader />}>
                      <IssueProspective />
                    </Suspense>
                  }
                />
                <Route
                  path="issued"
                  handle={{ breadcrumb: 'Issued' }}
                  element={
                    <Suspense fallback={<DefaultLoader />}>
                      <Issued />
                    </Suspense>
                  }
                />
              </Route>
              <Route
                path="receive"
                handle={{ breadcrumb: 'Receive' }}
                element={<Navigate to="receive/prospective" replace />}
              >
                <Route
                  path="prospective"
                  handle={{ breadcrumb: 'Prospective' }}
                  element={
                    <Suspense fallback={<DefaultLoader />}>
                      <ReceiveProspective />
                    </Suspense>
                  }
                />
                <Route
                  path="received"
                  handle={{ breadcrumb: 'Received' }}
                  element={
                    <Suspense fallback={<DefaultLoader />}>
                      <Received />
                    </Suspense>
                  }
                />
              </Route>
            </Route>
          </Route>
        </Route >
        <Route
          path='clinical'
          handle={{ breadcrumb: 'Clinical' }}
        >
          <Route
            path='profile'
            handle={{ breadcrumb: 'Profile' }}
          >
            <Route
              index
              element={
                <Suspense fallback={<DefaultLoader />}>
                  <ClinicalPatientList />
                </Suspense>
              }
              handle={{ breadcrumb: 'Patient List' }}
            />
            <Route
              path='medical-history'
              element={
                <Suspense fallback={<DefaultLoader />}>
                  <MedicalHistory />
                </Suspense>
              }
              handle={{ breadcrumb: 'Medical History' }}
            />

            <Route
              path='examination'
              element={
                <Suspense >
                  <Examination />
                </Suspense>
              }
              handle={{ breadcrumb: 'Examination' }}
            />

            <Route
              path='hpi'
              element={
                <Suspense >
                  <HPI />
                </Suspense>
              }
              handle={{ breadcrumb: 'HPI' }}
            />
            <Route
              path='investigation'
              element={
                <Suspense >
                  <InvestigationDiag />
                </Suspense>
              }
              handle={{ breadcrumb: 'Investigation' }}
            />

          </Route>

        </Route>
        {/* Add more routes here */}
      </Route>
    </>
  )
);

export default routes;
