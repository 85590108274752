/* eslint-disable no-console */
// navigationService.ts
let navigateFunction: (path: string) => void;

export const setNavigateFunction = (navigate: (path: string) => void) => {
  navigateFunction = navigate;
};

export const navigateTo = (path: string) => {
  if (navigateFunction) {
    navigateFunction(path);
  } else {
    console.error('Navigate function not set. Make sure to call setNavigateFunction in your app.');
  }
};