/* eslint-disable */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-console */
import React, { useEffect, useState } from 'react';
import { Grid, Button, Chip, Stack, Menu, MenuItem, alpha, styled, MenuProps, Divider, Box, Modal, Typography } from '@mui/material';
import style from './AppointmentTable.module.css';
import { Appointment, AppointmentData, AppointmentStatus, ColumnConfig, GroupConfig, VisitLog } from '../../types';
import EditableTable from '../customTableComponent';
import { useLoader } from '../loaderProvider/loader';
import { useAlert } from '../alertProvider';
import { getAppointmentsList, getVisitLogs, updateAppointmentStatus } from '../../services/apiService';
import { ReactComponent as VectorIcon } from '../../assets/SVG/dotline.svg';
import CustomModal from '../appointmentViewModal';
import AppointmentModal from './../appointmentForm/appointmentForm'; // Make sure to import the modal component
import { useTenant } from '../tenantProvider';
import { checkDatePassed } from '../../utils/commonUtils';
import registerUserIcon from '../../assets/SVG/register-user.svg';
import rescheduleIcon from '../../assets/SVG/Reschedule.svg';
import cancelAlertIcon from '../../assets/SVG/Cancel (1).svg';
import cancelIcon from '../../assets/SVG/Cancel.svg';
import closeIcon from '../../assets/SVG/close-circle-event.svg';
import checkInIcon from '../../assets/SVG/Check in.svg';
import checkOutIcon from '../../assets/SVG/tag-cross.svg';
import Rescheduled from '../appointmentRescheduleModal';
import Cancel from '../appointmentCancelModal';
import { useNavigate } from 'react-router-dom';

const groupConfig: GroupConfig[] = [
  { fields: ['date'], type: 'row' },
  { fields: ['TimeFrom'], type: 'row' },
  { fields: ['TimeTo'], type: 'row' },
  { fields: ['patientName', 'MRN'], type: 'column', defaultHeaderGroupname: 'Patient Name' },
  { fields: ['mobileNumber'], type: 'row' },
  { fields: ['department'], type: 'row' },
  { fields: ['doctor'], type: 'row' },
  { fields: ['Reason'], type: 'row' },
  { fields: ['action'], type: 'row' },
];

const columns: ColumnConfig[] = [
  { field: 'date', headerName: 'Date', type: 'date', editable: false },
  { field: 'TimeFrom', headerName: ' Time From', type: 'text', editable: false },
  { field: 'TimeTo', headerName: 'Time To', type: 'text', editable: false },
  { field: 'patientName', headerName: 'Patient Name', type: 'text', editable: false },
  { field: 'MRN', headerName: 'MRN', type: 'text', editable: false },
  { field: 'mobileNumber', headerName: 'Mobile Number', type: 'text', editable: false },
  { field: 'doctor', headerName: 'Doctor', type: 'text', editable: false },
  { field: 'department', headerName: 'Department', type: 'text', editable: false },
  { field: 'Reason', headerName: 'Reason', type: 'text', editable: false },
  { field: 'action', headerName: 'Action', type: 'text', editable: false },
];

interface AppointmentTableProps {
  departmentID?: number | undefined;
  departmentName?: string | '';
}

export default function AppointmentTable({ departmentID , departmentName }: AppointmentTableProps) {

  const { showLoader, hideLoader } = useLoader();
  const { addAlert } = useAlert();
  const { tenantId } = useTenant();
  const [appointmentData, setAppointmentData] = useState<AppointmentData[]>([]);
  // const [appointments, setAppointmentData] = useState<AppointmentData[]>([]);
  const [dataSize, setDataSize] = useState<number | undefined>();
  // const [open, setOpen] = useState<boolean>(false);
  const [modalOpen, setModalOpen] = useState(false); // State for modal open/close
  const [doctor, setDoctor] = useState(''); // State for patient name
  const [appointments, setAppointments] = useState<Appointment[] | undefined>();
  const [scheduled, setScheduled] = useState(0);
  const [checkedIn, setCheckedIn] = useState(0);
  const [checkedOut, setCheckedOut] = useState(0);
  const [unAttended, setUnAttended] = useState(0);
  const [cancelled, setCancelled] = useState(0);
  const [viewModal, setViewModalOpen] = useState<boolean>(false);
  const [appSelectedId, setAppSelectedId] = useState<number>(0);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [rescheduled, setRescheduled] = useState<boolean>(false);
  const [cancelView, setCancelView] = useState<boolean>(false);
  const [previousVisits, setPreviousVisits] = useState<Array<VisitLog>>([]);
  const [selectedApp, setSelectedApp] = useState<Appointment | undefined>();
  const [actionsVisibility, setActionsVisibility] = useState({
    isCheckedIn: true,
    isRegister: false,
    isCheckOut: false
  });
  const open = Boolean(anchorEl);
  const navigate = useNavigate();

  const actionModalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 'auto',
    height: 'auto',
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 3,
    borderRadius: '20px'
  };

  const handleStatusClick = (event: React.MouseEvent<HTMLButtonElement>, data: Appointment | undefined) => {
    setSelectedApp(data);
    const isPast = checkDatePassed(data?.appointment_date || '');
    const isToday = data?.appointment_date === new Date().toISOString().split('T')[0];
    
    if (isPast || data?.status === AppointmentStatus.CHECKED_OUT) {
      setViewModalOpen(true);
      setAppSelectedId(data?.id || 0);
    } else {
      setAnchorEl(event.currentTarget);
    }
    

    if(isToday){
      if(data?.status === AppointmentStatus.CHECKED_IN) {
        setActionsVisibility({...actionsVisibility, isCheckedIn: false, isRegister: false, isCheckOut: true});
      } else if(data?.status === AppointmentStatus.CHECKED_OUT && (data?.checked_out_time || data?.checked_in_time)) {
        setAppSelectedId(data?.id || 0);
        setViewModalOpen(true);
      } else {
        setActionsVisibility({...actionsVisibility, isCheckedIn: true, isRegister: true, isCheckOut: false});
      }
    } else if(!isToday) {
      setActionsVisibility({...actionsVisibility, isCheckedIn: false, isRegister: true, isCheckOut: false});
    }
  };
  
  const handleClose = () => {
    setAnchorEl(null);
    setViewModalOpen(false);
    setActionsVisibility({...actionsVisibility,  isCheckedIn: true,
      isRegister: false,
      isCheckOut: false});
    setRescheduled(false);
    setCancelView(false);
  };


  const handleOnChange = (id: number, field: string, value: string) => {
    console.log('On Change:', { id, field, value });
  };

  const handleAction = (id: number) => {
    console.log('Action Triggered for ID:', id);
  };

  const formatTimeTo12Hour = (time: string | undefined) => {
    if (!time || typeof time !== 'string' || !time.includes(':')) {
      console.error('Invalid time format:', time);
      return '';
    }

    const date = new Date(time);
    const hours = date.getUTCHours();
    const minutes = date.getUTCMinutes();

    const ampm = hours >= 12 ? 'PM' : 'AM';
    const formattedHours = hours % 12 || 12;
    return `${formattedHours}:${String(minutes).padStart(2, '0')} ${ampm}`;
  };

  const getCurrentTime = () => {
    const now = new Date();
    return now.toTimeString().split(':').slice(0, 2).join(':');
  };

  const getEndTime = (startTime: string) => {
    const [hour, minute] = startTime.split(':');
    const date = new Date();
    date.setHours(parseInt(hour, 10));
    date.setMinutes(parseInt(minute, 10) + 30); // Add 30 minutes
    return date.toTimeString().split(':').slice(0, 2).join(':');
  };

  // Default values (fallback logic in the parent component)
  const defaultAppointmentDate = new Date();
  // const defaultAppointmentDate = new Date('2024-10-04T02:15:00+05:30');
  // const defaultAppointmentDate = 'Fri Oct 04 2024 00:15:00 GMT+0530 (India Standard Time)';

  const defaultAppointmentTime = getCurrentTime();
  const defaultAppointmentEndTime = getEndTime(defaultAppointmentTime);

  const calculateStatusCounts = (appointments: Appointment[]) => {
    return appointments.reduce(
      (counts, appointment) => {
        switch (appointment.status) {
        case AppointmentStatus.CANCELLED:
          counts.cancelled += 1;
          break;
        case AppointmentStatus.CHECKED_IN:
          counts.checkedIn += 1;
          break;
        case AppointmentStatus.CHECKED_OUT:
          counts.checkedOut += 1;
          break;
        case AppointmentStatus.SCHEDULED:
          counts.scheduled += 1;
          break;
        case AppointmentStatus.UNATTENDED:
          counts.unAttended += 1;
          break;
        }
        return counts;
      },
      { cancelled: 0, checkedIn: 0, checkedOut: 0, scheduled: 0, unAttended: 0 }
    );
  };


  useEffect(() => {
    if (appointments) {
      const statusCounts = calculateStatusCounts(appointments);
      setCancelled(statusCounts.cancelled);
      setCheckedIn(statusCounts.checkedIn);
      setCheckedOut(statusCounts.checkedOut);
      setScheduled(statusCounts.scheduled);
      setUnAttended(statusCounts.unAttended);
    }
  }, [appointments]); // Only `appointments` is required as a dependency

  const StyledMenu = styled((props: MenuProps) => (
    <Menu
      elevation={0}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      {...props}
    />
  ))(({ theme }) => ({
    '& .MuiPaper-root': {
      borderRadius: 6,
      marginTop: theme.spacing(1),
      minWidth: 180,
      color: 'rgb(55, 65, 81)',
      boxShadow:
        'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
      '& .MuiMenu-list': {
        padding: '4px 0',
      },
      '& .MuiMenuItem-root': {
        '& .MuiSvgIcon-root': {
          fontSize: 18,
          color: theme.palette.text.secondary,
          marginRight: theme.spacing(1.5),
        },
        '&:active': {
          backgroundColor: alpha(
            theme.palette.primary.main,
            theme.palette.action.selectedOpacity,
          ),
        },
      },
      ...theme.applyStyles('dark', {
        color: theme.palette.grey[300],
      }),
    },
  }));

  const updateStatus = (status: string, id: number | undefined) => {

    updateAppointmentStatus(id || 0, status)
      .then((response) => {
        console.log(response);
        setAnchorEl(null);
      })
      .catch((error) => {
        addAlert(`Error: ${error}`, 'error')
        console.error('Error updating status:', error);
      });
  };

  const handleModalClose = () => {
    setRescheduled(false);
    setCancelView(false);
  };

  const handleRescheduleView = () => {
    setRescheduled(true);
    setAnchorEl(null);
    setActionsVisibility({...actionsVisibility,  isCheckedIn: true,
      isRegister: false,
      isCheckOut: false});
  };

  const handleCancelView = () => {
    setCancelView(true);
    setAnchorEl(null);
    setActionsVisibility({...actionsVisibility,  isCheckedIn: true,
      isRegister: false,
      isCheckOut: false});
  };

  useEffect(() => { 
    if (selectedApp?.patient?.id) {
      try {
        const query = 'order_by=created_on'; 

        getVisitLogs(selectedApp?.patient?.id, query)
          .then((logs) => {
            setPreviousVisits(logs.objects);
          })
          .catch((error) => {
            console.error('Error fetching visit logs:', error);
          });
      } catch (error) {
        console.error('Unexpected error:', error);
      }
    }
  }, [selectedApp?.patient?.id]);


  const isNewPatient = !previousVisits?.some((item: { [key: string]: any }) => {
    const appointmentDate = new Date(item.appointment_date).getTime();
    const today = new Date().setHours(0, 0, 0, 0);
    return appointmentDate < today;
  });
  
  useEffect(() => {
    showLoader();

    // Fetch appointments without async/await
    const fetchAppointments = () => {
      // Make sure to handle the case when departmentID is not defined
      if (departmentID) {
        getAppointmentsList(tenantId ,departmentID)
          .then((appointmentDataResponse) => {
            setDataSize(appointmentDataResponse.meta.total_count);
            setAppointments(appointmentDataResponse.objects);
            const transformedAppointments: AppointmentData[] = appointmentDataResponse.objects.map((appointment: Appointment) => {
              const createdDate = new Date(appointment.appointment_date);
              return {
                id: appointment.id,
                gender: appointment.patient?.gender || '-',
                age: appointment.patient?.age || '-',
                reasonOfVisit: appointment.patient?.additional_info?.reason_for_visit || '-',
                date: `${createdDate.getUTCDate().toString().padStart(2, '0')}/${(createdDate.getUTCMonth() + 1).toString().padStart(2, '0')}/${createdDate.getUTCFullYear()}`,
                TimeFrom: formatTimeTo12Hour(appointment.start_datetime),
                TimeTo: formatTimeTo12Hour(appointment.end_datetime),
                patientName: `${appointment.patient?.first_name || ''} ${appointment.patient?.last_name || ''}`.trim() || '-',
                MRN: `${appointment.patient?.medical_record_no || '-'} | ${appointment.patient?.gender || '-'}`,
                mobileNumber: appointment.patient?.mobile_number || '-',
                doctor: appointment.user.username || '',
                firstvisit: appointment.department__name || '-',
                action: (
                  <div  
                    style={{ cursor: 'pointer' }}>
                    <Button
                      id="demo-customized-button"
                      aria-controls={open ? 'demo-customized-menu' : undefined}
                      aria-haspopup="true"
                      aria-expanded={open ? 'true' : undefined}
                      disableElevation
                      onClick={(e) => handleStatusClick(e, appointment)}
                    >
                      <VectorIcon />
                    </Button>
                    <StyledMenu
                      id="demo-customized-menu"
                      MenuListProps={{
                        'aria-labelledby': 'demo-customized-button',
                      }}
                      anchorEl={anchorEl}
                      open={open}
                      onClose={handleClose}
                    >
                      { (actionsVisibility.isRegister && !selectedApp?.patient?.additional_info?.ethnicity?.length) && <MenuItem onClick={() => navigate('/admin/registration/patient-partner-registration', { state: selectedApp?.patient })}>
                        <img src={registerUserIcon} width={22} style={{ marginRight: 5 }} alt='register-icon' />
                        Register
                      </MenuItem>}
                      { (actionsVisibility.isCheckedIn && !selectedApp?.checked_in_time) && 
                      <MenuItem disabled={selectedApp?.patient?.additional_info?.ethnicity?.length ? false : true} onClick={() => updateStatus(AppointmentStatus.CHECKED_IN, selectedApp?.id)}>
                        <img src={checkInIcon} style={{ marginRight: 5 }} alt='checkIn-icon' />
                        Check-In
                        { !selectedApp?.patient?.additional_info?.ethnicity?.length && <Typography color='warning' ml={2}>(Registration form must be completed before check-in)</Typography>}
                      </MenuItem>}
                      { actionsVisibility.isCheckOut && <MenuItem onClick={() => updateStatus(AppointmentStatus.CHECKED_OUT, selectedApp?.id)}>
                        <img src={checkOutIcon} style={{ marginRight: 5 }} alt='checkOut-icon' />
                        Check-Out
                      </MenuItem>}
                      
                      <MenuItem onClick={handleRescheduleView}>
                        <img src={rescheduleIcon} width={22} style={{ marginRight: 5 }} alt='register-icon' />
                        Reschedule
                      </MenuItem>
                      <MenuItem onClick={handleCancelView}>
                        <img src={cancelIcon} width={22} style={{ marginRight: 5 }} alt='cancel-user' />
                        Cancel
                      </MenuItem>
                    </StyledMenu>

                  </div>

                ),
                appointmentDate: appointment.appointment_date || '-',
                appointmentReason: appointment.appointment_reason__reason?.toString ? appointment.appointment_reason__reason.toString() : '-',
                department: appointment.department__name || '-',
                Reason: appointment.appointment_reason__reason || '-', // Map the reason for visit correctly here
                startTime: appointment.start_datetime || '-',
                endTime: appointment.end_datetime || '-',
                patient: `${appointment.patient?.first_name || ''} ${appointment.patient?.last_name || ''}`.trim() || '-',
              };
            });

            setAppointmentData(transformedAppointments);
          })
          .catch((error) => {
            console.error('Error fetching appointments:', error);
            addAlert('Error fetching appointments. Please try again later.', 'error');
          })
          .finally(() => {
            hideLoader();
          });
      } else {
        hideLoader();
      }
    };

    fetchAppointments();
  }, [addAlert, showLoader, hideLoader, tenantId, departmentID, anchorEl, rescheduled]);

  const handleOpenModal = () => {
    setModalOpen(true); // Open the modal
    setDoctor(''); // Optionally reset patient name or set it to a specific value
  };

  const handleCloseModal = () => {
    setModalOpen(false); // Close the modal
  };


  return (
    <div className={style.container}>
      <Grid container spacing={3} className={style.container} alignItems='center' sx={{ mt: 2, px: 2 }}>
        <Grid item xs={6}>
          <h2 className={style.title}>Patients List</h2>
        </Grid>
        <Grid item xs={6} style={{ textAlign: 'right' }}>
          <Button className={style.button} variant="contained" onClick={handleOpenModal}> {/* Open modal on click */}
            New Appointment
          </Button>
        </Grid>
      </Grid>
      <div className={style.status}>
        <Stack direction="row" spacing={1}>
          <Chip label={`Scheduled ${scheduled}`} variant="outlined" color='primary'/>
          <Chip label={`Checked-In ${checkedIn}`} variant="outlined" color='warning'/>
          <Chip label={`Checked-Out ${checkedOut}`} variant="outlined" color='warning'/>
          <Chip label={`Un-Attended ${unAttended}`} variant="outlined" color='success'/>
          <Chip label={`Canceled ${cancelled}`} variant="outlined" color='error'/>
        </Stack>
      </div>
      <div className='mt-4'>
        <EditableTable
          columns={columns}
          data={appointmentData}
          actionType='none'
          onAction={handleAction}
          onChange={handleOnChange}
          dataSize={dataSize}
          groupConfig={groupConfig}
        />
      </div>

      { viewModal ? <CustomModal id={appSelectedId} isOpen={viewModal} onClose={() => setViewModalOpen(false)} /> : null}

      <Modal open={rescheduled || cancelView} onClose={handleClose}>
        <Box sx={actionModalStyle}>
            
          {/* Rescheduled / Cancel Heading */}
          <Stack direction='row' alignItems='center' justifyContent='space-between'>
            <div style={{ display: 'flex', alignItems: 'center', gap: 12 }}>
              <div>
                <img src={cancelAlertIcon} alt='cancel-icon' />
              </div>
  
              <div>
                <Typography fontFamily='Montserrat' fontWeight={600} fontSize='20px' color='#232323'>
                  {rescheduled ? 'Reschedule Appointment ?' : 'Cancel Appointment ?'}
                </Typography>
                <Typography fontFamily='Nunito' fontWeight={400} fontSize='14px' color='#404653'>
                  {rescheduled ? 'Are you sure you want to reschedule this appointment ?' : 'Are you sure you want to cancel this appointment ?'}
                </Typography>
              </div>
            </div>
            <img src={closeIcon} style={{ cursor: 'pointer' }} onClick={handleModalClose} width={28} alt='close-icon' />
          </Stack>
  
  
          {
            rescheduled ?
              <Rescheduled data={selectedApp} onClose={handleModalClose} /> : 
              cancelView ? 
                <Cancel data={selectedApp} onClose={handleModalClose} /> : 
                null
          }
  
        </Box>
      </Modal>

      {/* Render the AppointmentModal conditionally */}
      <div>
        <AppointmentModal
          open={modalOpen}
          onClose={handleCloseModal}
          slotDate={defaultAppointmentDate}
          slotDepartment= {departmentID}
          departmentName={departmentName}
        />
      </div>

    </div>
  );
}
