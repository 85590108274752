/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-console */

import React, { useEffect, useMemo, useState } from 'react';
import { Autocomplete, Button, Grid, IconButton, TextField } from '@mui/material';
import styles from './DiagnosisHome.module.css'; 
import HPIBack from '../../assets/SVG/HPIBack.svg'; 
import EditableTable from '../customTableComponent';
import { ColumnConfig, DiagnosisMaster, GroupConfig, TableRowData } from '../../types'; 
import { DiagnosisMenuItems, DiagnosisTypeEnum, getDiagnosis, getDoctors, PatientDiagnosis, postDiagnosis, User} from './diagnosisHelper';
import { ConfirmationModal } from '../confirmationModal';
import { usePatientId } from '../patientIdcontext';
import { useAlert } from '../alertProvider';
import { useLoader } from '../loaderProvider/loader';
import { patientDetailsObservable } from '../../services/sharedService';



  
const groupConfig: GroupConfig[] = [
  { fields: ['code'], type: 'column' },
  { fields: ['name'], type: 'column' },
  { fields: ['type'], type: 'column' },
  { fields: ['doctor'], type: 'column' },
  { fields: ['notes'], type: 'column' },
];
  

const AddDiagnosis: React.FC<{ onBackClick: () => void }> = ({ onBackClick }) => {
  const [diagnosis, setDiagnosis] = useState<DiagnosisMaster[]>([]);
  
  const [doctors, setDoctors] = useState<User[]>([]);
  const [columns, setColumns] = useState<ColumnConfig[]>([]);
  const [rows, setRows] = useState<TableRowData[]>([]);
  const [selectedDiagnosis, setSelectedDiagnosis] = useState(null);
  const [inputValue, setInputValue] = useState(''); // State for input value
  const [openModal, setOpenModal] = useState(false);
  const patientId =usePatientId();

  
  const { addAlert } = useAlert();
  const { showLoader, hideLoader } = useLoader();

  const handleOpenModal = () => {setOpenModal(true);};
  const handleCloseModal = () => {setOpenModal(false);};

  const [patientDetails, setpatientDetails] = useState<any >(null);


  useEffect(() => {
    const subscription = patientDetailsObservable.subscribe((value) => {
      setpatientDetails(value);
    });

    return () => subscription.unsubscribe();
  }, []);
  useEffect(() => {
    getDoctors().then((res) => {
      setDoctors(res.objects);
    });
  }, []);

  const computedColumns = useMemo(() => {
    return [
      { field: 'code', headerName: 'Code', type: 'text', editable: false, hideLabel: true },
      { field: 'name', headerName: 'Name', type: 'text', editable: false, hideLabel: true },
      {
        field: 'type',
        headerName: 'Type',
        type: 'select',
        editable: true,
        options: DiagnosisMenuItems.map(item => item.value),
        hideLabel: true
      },
      {
        field: 'doctor',
        headerName: 'Doctor',
        type: 'select',
        editable: true,
        options: doctors.map(doctor => (`Dr. ${doctor.first_name} ${doctor.last_name}`)),
        hideLabel: true
      },
      { field: 'notes', headerName: 'Notes', type: 'text', editable: true, hideLabel: true }
    ] as ColumnConfig[];
  }, [doctors]);

  useEffect(() => {
    setColumns(computedColumns);
  }, [computedColumns]);

  const handleSelectionChange = ( value: any) => {
    console.log(value);
    const selectedRow = value;
    setSelectedDiagnosis(value);
    setInputValue('');
    
    if (selectedRow) {
      const newRow: TableRowData = {
        id: selectedRow.id,
        code: selectedRow.code,
        name: selectedRow.name,
        type: '',  
        doctor: '', 
        notes: '', 
      };
      setRows(prevRows => [...prevRows, newRow]);
    }

  };

  const handleOnChange = (id: number, field: string, value: string) => {
    console.log('On Change:', { id, field, value });

    setRows(prevRows =>
      prevRows.map(row =>
        row.id === id ? { ...row, [field]: value } : row 
      )
    );


  };

  const handleAction = (id: number) => {
    setRows(prevRows => prevRows.filter(row => row.id !== id));
    console.log('Deleted row with ID:', id);
  };

  const handleSearch = (searchValue: string) => {
    const query = `?gender=${patientDetails.gender}&search=${searchValue}`;
    getDiagnosis(query).then((res) => {
      setDiagnosis(res.objects);
    });
  };


  const handleSave = () => {
    showLoader();
    console.log(rows);
  
    const convertedData:PatientDiagnosis[] = rows.map(test => {
      const doctorNameParts = test.doctor.split(' ');
      const doctorFirstName = doctorNameParts[1];
      const doctorLastName = doctorNameParts[2];
  
      const foundDoctor = doctors.find(doctor => 
        doctor.first_name === doctorFirstName && doctor.last_name === doctorLastName
      );
  
      return {
        patient: patientId,       
        diagnosis: test.id,            
        diagnosis_type: test.type as DiagnosisTypeEnum, 
        doctor: foundDoctor ? foundDoctor.id : 0,
        notes: test.notes || ''
      };
    });
  

    const addDiagnosis = async (data:PatientDiagnosis) => {
      return await postDiagnosis(data);
    };
    
    Promise.all(convertedData.map(data => addDiagnosis(data)))
      .then(() => {
        addAlert('Diagnosis Added Successfully', 'success');
        setRows([]);
        hideLoader();
        onBackClick();
      })
      .catch((error) => {
        console.error('An error occurred:', error);
        addAlert('An error occurred while saving the Diagnosis. Please try again.', 'error');
      });
    
    console.log(convertedData);


  };
  
  const handleCancel= ()=>{
    setRows([]);
    setDiagnosis([]);
    setInputValue(''); 
    setSelectedDiagnosis(null);

  };

  return (
    <div className={styles.home}>
      <Grid container alignItems="center" spacing={2}>
        <Grid item xs={12} sm="auto">
          <IconButton onClick={onBackClick}>
            <img src={HPIBack} alt="Back" style={{ width: '2.5rem', height: '2.5rem' }} />
          </IconButton>
          <span className={styles.addDiagnosisTitle}>Add Diagnosis</span>
        </Grid>
      </Grid>

      <Grid container alignItems="center" spacing={2}>
        <Grid item xs={12} sm={6}>
          <Autocomplete
            disablePortal
            fullWidth
            options={diagnosis}
            value={selectedDiagnosis} // Bind the selected value
            inputValue={inputValue} // Bind the input value
            onChange={(e, value) => handleSelectionChange(value)}
            onInputChange={(e, value) => {
              setInputValue(value); // Update input value state
              handleSearch(value); // Call your search function
            }}
            getOptionLabel={(option) => option.name || ''}
            renderInput={(params) => <TextField {...params} label="Search" />}
          />
        </Grid>
      </Grid>

      <div className="mt-4" style={{ flexGrow: 1 }}>

        {columns.length > 0 ? ( // Check if columns have been populated
          <EditableTable
            columns={columns}
            data={rows} // Using `diagnosis` as the data source
            actionType="delete"
            onAction={handleAction}
            onChange={handleOnChange}
            groupConfig={groupConfig}
          />
        ) : (
          <p>Loading...</p> // You can render a loading state here
        )}

      </div>

      <Grid container spacing={2} className={`${styles.gridcontainer} mt-4`}>
        <Button
          variant="outlined"
          sx={{
            width: '8.5625rem',
            height: '2.875rem',
            borderRadius: '0.625rem',
            border: '1px solid #000000',
            backgroundColor: 'white',
            fontFamily: 'Montserrat',
            fontSize: '1rem',
            fontWeight: 700,
            lineHeight: '1.375rem',
            textAlign: 'left',
            color: '#505050',
            '&:hover': {
              backgroundColor: '#F3F3F3',
            },
          }}
          onClick={handleCancel}
        >
          Cancel
        </Button>

        <Button
          variant="contained"
          sx={{
            width: '7.5rem',
            height: '2.875rem',
            borderRadius: '0.625rem',
            backgroundColor: '#F3F3F3',
            fontFamily: 'Montserrat',
            fontSize: '1rem',
            fontWeight: 700,
            lineHeight: '1.375rem',
            textAlign: 'left',
            color: '#505050',
            '&:hover': {
              backgroundColor: '#E0E0E0',
            },
          }}
          onClick={handleOpenModal}
          disabled={rows.length === 0}  
        >
          Save
        </Button>
      </Grid>
      <ConfirmationModal
        open={openModal}
        handleClose={handleCloseModal}
        onConfirm={handleSave}
        type={'save'}
        title="Diagnosis"
        contentMessage="Do you want to save the Selected Diagnosis?"
      />
    </div>
  );
};

export default AddDiagnosis;
