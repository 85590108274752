/* eslint-disable @typescript-eslint/no-unused-vars */

/* eslint-disable no-console */
import { Modal, Box, Typography, TextField, Button, FormHelperText, Autocomplete, Skeleton } from '@mui/material';
import React, { FC, useEffect, useState } from 'react';
import './Calendar.css';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { bookOutOfOffice, getMyProfile, getMyProfileBasic, getUsersByClinic, getUsersByClinicOnly } from '../../services/apiService';
import { useLoader } from '../loaderProvider/loader';
import { DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import WorkOffIcon from '@mui/icons-material/WorkOff';
import { DateRange, OutOfOfficeRequest, splitDateRangeIntoDailyGroups } from './Calendar.helper';
import { convertDateToISOWithOffset, formatDateToDDMMYYYY, formatDateToYYYYMMDD } from '../../services/time';
import { UserBasic } from '../../types';
import { useAlert } from '../alertProvider';
import { AxiosError } from 'axios';
import { useTenant } from '../tenantProvider';
import DefaultLoader from '../defaultLoader';

interface OutOfOfficeFormProps {
  userId?: number;
  open: boolean;
  handleClose: () => void;
}

const OutOfOfficeForm: FC<OutOfOfficeFormProps> = ({ open, handleClose }) => {
  const [user, setUser] = useState<UserBasic | undefined>();
  const { showLoader, hideLoader } = useLoader();
  const { addAlert } = useAlert();
  const [users, setUsers] = React.useState<UserBasic[] | undefined>(undefined);
  const { tenantId } = useTenant();
  const [startDateTime, setStartDateTime] = useState<Date | null>(null);


  useEffect(() => {
    showLoader();
    getUsersByClinicOnly(tenantId).then((userRes) => setUsers(userRes.objects));
    getMyProfileBasic()
      .then((response: UserBasic) => setUser(response))
      .catch((error) => console.error(error))
      .finally(() => hideLoader());
  }, [hideLoader, showLoader, tenantId]);

  const handleModelClose = () => {
    setUser(undefined);
    setUsers(undefined);
    handleClose();
  };

  const formik = useFormik({
    initialValues: {
      start_datetime: null as Date | null, // use null initially
      end_datetime: null as Date | null,
      reason: ''
    },
    validationSchema: Yup.object({
      start_datetime: Yup.date()
        .required('Start date and time is required')
        .min(new Date(), 'Start date and time must be in the future')
        .typeError('Invalid datetime format'),
      end_datetime: Yup.date()
        .required('End date and time is required')
        .min(
          Yup.ref('start_datetime'),
          'End date and time must be after the start date and time'
        )
        .typeError('Invalid datetime format'),
      reason: Yup.string().required('Reason is required')
    }),
    onSubmit: async (values) => {
      if (user) {
        const dates = splitDateRangeIntoDailyGroups(values.start_datetime as Date, values.end_datetime as Date);
        dates.forEach((date: DateRange) => {
          const request: OutOfOfficeRequest = {
            user: user.id,
            end_datetime: convertDateToISOWithOffset(date.end),
            start_datetime: convertDateToISOWithOffset(date.start),
            remark: values.reason,
            type: 'OUT_OF_OFFICE',
            appointment_date: formatDateToDDMMYYYY(date.start),
            room: null,
            table: null
          };
          console.log(request);
          bookOutOfOffice(request).then(() => {
            addAlert('Out of Office Marked successfully', 'success');
          }).catch((error: AxiosError) => addAlert(error.response?.statusText || 'Error occurred', 'error')).finally(() => {
            hideLoader();
            handleModelClose();
          });
        });
      }
    }
  });

  const handleChange = (event: React.SyntheticEvent, newValue: { label: string; id: number } | null) => {
    users?.forEach((user: UserBasic) => {
      if (user.id === newValue?.id) {
        setUser(user);
        return;
      }
    });
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
    >
      <Box className="cal-ooo-modal">
        <div className='cal-ooo-heading'>
          <Autocomplete
            size='small'
            options={users?.map((user: UserBasic) => ({ label: `${user.first_name} ${user.last_name}`, id: user.id })) || []}
            sx={{ width: 300 }}
            renderInput={(params) => <TextField {...params} label="Choose another User" />}
            getOptionLabel={(option) => option.label} // Set display label
            isOptionEqualToValue={(option, value) => option.id === value.id}
            onChange={handleChange}
          />
          <div>
            <Typography id="modal-modal-title" variant="h5" component="h3" fontFamily={'nunito'} fontWeight={700} color='#E17E61'>
              <WorkOffIcon/> Mark Out of Office
            </Typography>
          </div>
        </div>
        <div className="cal-ooo-form" >
          <Typography id="modal-modal-title" variant="h4" component="h2" fontFamily={'nunito'} color='#747373'>
            <label>{`${user?.first_name} ${user?.last_name}`}</label>
          </Typography>
          <div className='cal-ooo-secinfo'>
            <Typography id="modal-modal-title" variant="h6" component="h2" fontFamily={'nunito'} fontWeight={700} color='#747373'>
              <label>{user?.email}</label>
            </Typography>
            <Typography id="modal-modal-title" variant="h6" component="h2" fontFamily={'nunito'} fontWeight={200} color='#747373'>
              <label>{user?.username}</label>
            </Typography>
          </div>
          <form onSubmit={formik.handleSubmit}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DateTimePicker
                label="Start Date & Time"
                value={formik.values.start_datetime}
                onChange={(datetime: Date | null) => {
                  formik.setFieldValue('start_datetime', datetime);
                  setStartDateTime(datetime); // Store start date-time to limit end date-time options
                }}
                minDateTime={new Date()} // Only allow future dates
                slotProps={{
                  textField: {
                    fullWidth: true,
                    margin: 'normal',
                    onBlur: formik.handleBlur,
                    error: Boolean(formik.errors.start_datetime && formik.touched.start_datetime),
                    helperText: formik.touched.start_datetime && formik.errors.start_datetime
                  },
                }}
              />
              <DateTimePicker
                label="End Date & Time"
                disabled={!startDateTime}
                value={formik.values.end_datetime}
                onChange={(datetime: Date | null) => formik.setFieldValue('end_datetime', datetime)}
                minDateTime={startDateTime || new Date()} // Limit end date-time to after start date-time
                slotProps={{
                  textField: {
                    fullWidth: true,
                    margin: 'normal',
                    onBlur: formik.handleBlur,
                    error: Boolean(formik.errors.end_datetime && formik.touched.end_datetime),
                    helperText: formik.touched.end_datetime && formik.errors.end_datetime
                  },
                }}
              />
            </LocalizationProvider>

            <TextField
              label="Reason"
              name="reason"
              value={formik.values.reason}
              onChange={formik.handleChange}
              fullWidth
              margin="normal"
              multiline
              rows={3}
              error={Boolean(formik.errors.reason && formik.touched.reason)}
              helperText={formik.touched.reason && formik.errors.reason}
            />
            <Box display="flex" justifyContent="flex-end" mt={2}>
              <Button onClick={handleClose} color="secondary" variant="outlined" style={{ marginRight: '8px' }}>
              Cancel
              </Button>
              <Button type="submit" color="primary" variant="contained">
              Submit
              </Button>
            </Box>
          </form>
        </div>
      </Box>
    </Modal>
  );
};

export default OutOfOfficeForm;