import { Regions, User } from '../types';
import { getMyProfile } from './apiService';

export async function getCurrentRegion(): Promise<Regions | undefined> {
  const userString = localStorage.getItem('user');
  if (userString) {
    // Parse the JSON string and cast it to the User interface
    const user: User = JSON.parse(userString);
    // return user.tenant.region;
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    return getRegionFromString(user.tenant.region);

  } else {
    // we must block if user is not there, a rare condition...
    const user = await getMyProfile();
    localStorage.setItem('user', JSON.stringify(user));
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    return getRegionFromString(user.tenant.region);
  }
  
}

export async function getTenantRegion(): Promise<string> {
  const userString = localStorage.getItem('user');
  if (userString) {
    // Parse the JSON string and cast it to the User interface
    const user: User = JSON.parse(userString);
    // return user.tenant.region;
    return user.tenant.name;
  
  } else {
    // we must block if user is not there, a rare condition...
    const user = await getMyProfile();
    localStorage.setItem('user', JSON.stringify(user));
    return user.tenant.name;
  }
    
}

const getRegionFromString = (region: string): Regions | undefined => {
  if (Object.values(Regions).includes(region as Regions)) {
    return region as Regions;
  }
  return undefined;
};

export const formatDate = (date: Date) => {
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-indexed
  const day = String(date.getDate()).padStart(2, '0');
    
  return `${year}-${month}-${day}`;
};