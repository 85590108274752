import React from 'react';
import DiagnosisHome from '../../../../components/Diagnosis/DiagnosisHome';

const Diagnosis = () => {
  const handleAddClick = () => {

    // eslint-disable-next-line no-console
    console.log('Add button clicked in Diagnosis component');
  };

  return (
    <div>
      <DiagnosisHome onAddClick={handleAddClick} />
    </div>
  );
};

export default Diagnosis;
