/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-console */
import React, { useEffect, useState } from 'react';
import { Grid, Button, Box } from '@mui/material';
import EditableTable from '../../customTableComponent/index'; // Adjust the path as necessary
import { ColumnConfig, GroupConfig, TableRowData } from '../../../types'; // Import necessary types
import { getVisitLogs } from '../../../services/apiService';

// Updated VisitLog interface
interface VisitLog {
  appointment_date: string;
  time: string; // Assuming this is a string representing time
  user: string;
  department: string;
  reason: string;
  remark: string;
  id?: number; // Add an id for row identification
}

// Define the Props for PreviousVisits component
interface PreviousVisitsProps {
  patientId: number | null; // Expecting patientId to fetch logs
  showTitle?: boolean;
  count?: number
}

// Column configuration for the EditableTable
const columns: ColumnConfig[] = [
  { field: 'dateTime', headerName: 'Date | Time', type: 'text', editable: false ,hideLabel: true },
  { field: 'user', headerName: 'Name', type: 'text', editable: false ,hideLabel: true },
  { field: 'department', headerName: 'Department', type: 'text', editable: false ,hideLabel: true },
  { field: 'reason', headerName: 'Reason of Visit', type: 'text', editable: false,hideLabel: true  },
  { field: 'remark', headerName: 'Remarks', type: 'text', editable: false ,hideLabel: true }, 
];

// Group configuration for VisitLog
const groupConfig: GroupConfig[] = [
  { fields: ['dateTime'], type: 'row' },
  { fields: ['user'], type: 'column', defaultHeaderGroupname: 'Name' },
  { fields: ['department'], type: 'column', defaultHeaderGroupname: 'Department' },
  { fields: ['reason'], type: 'column', defaultHeaderGroupname: 'Visit Reason' },
  { fields: ['remark'], type: 'row' },
];

// Helper function to format date and time
const formatDateTime = (date: string, time: string) => {
  const formattedDate = new Date(date).toLocaleDateString('en-GB'); // Format as DD/MM/YYYY
  return `${formattedDate} | ${time}`; // Combine date and time
};

// Helper function to transform VisitLog into TableRowData
const transformVisitLogsToTableRowData = (logs: VisitLog[]): TableRowData[] => {
  if (!Array.isArray(logs)) {
    console.error('Expected logs to be an array, received:', logs);
    return []; // Return an empty array if logs is not an array
  }
  return logs.map((log, index) => ({
    dateTime: formatDateTime(log.appointment_date, log.time), // Format date and time here
    user: log.user,
    department: log.department,
    reason: log.reason,
    remark: log.remark,
    id: log.id ?? index + 1,
  }));
};

// Main PreviousVisits component
const PreviousVisits: React.FC<PreviousVisitsProps> = ({ patientId, showTitle = true , count = 2}) => {
  const [tableData, setTableData] = useState<TableRowData[]>([]);
  const [previousVisits, setPreviousVisits] = useState<VisitLog[]>([]);
  const [visibleCount, setVisibleCount] = useState(count); // Set initial visible records count to 3

  useEffect(() => { 
    if (patientId) {
      try {
        const query = 'order_by=created_on'; 

        getVisitLogs(patientId, query)
          .then((logs) => {
            setPreviousVisits(logs.objects);
          })
          .catch((error) => {
            console.error('Error fetching visit logs:', error);
          });
      } catch (error) {
        console.error('Unexpected error:', error);
      }
    }
  }, [patientId]);  

  useEffect(() => {
    setTableData(transformVisitLogsToTableRowData(previousVisits));
  }, [previousVisits]);

  // Handle the "See More" button click
  const handleSeeMore = () => {
    setVisibleCount((prevCount) => prevCount + 3); // Load 3 more records each time
  };

  return (
    <Grid container spacing={2} my={1} mx={1}>
      {showTitle && (
        <Grid item xs={12}>
          <strong>Previous Visits</strong>
        </Grid>
      )}

      <Grid item xs={12}>
        {previousVisits.length === 0 ? (
          <div>No previous visits found.</div>
        ) : (
          <>
            <EditableTable
              columns={columns}
              data={tableData.slice(0, visibleCount)} // Show only the visible records (3 at a time)
              actionType="none"
              groupConfig={groupConfig}
              onChange={(id: any, field: any, value: any) => {
                setTableData((prevData) =>
                  prevData.map((row) =>
                    row.id === id ? { ...row, [field]: value } : row
                  )
                );
              }}
            />
            {visibleCount < tableData.length && ( // Show "See More" button if there are more records
              <Box display="flex" justifyContent="center" mt={1}>
                <Button 
                  variant="outlined" 
                  onClick={handleSeeMore} 
                  size="small" // Smaller button
                  sx={{ padding: '4px 10px' }} // Adjust padding for a smaller size
                >
                  See More
                </Button>
              </Box>
            )}
          </>
        )}
      </Grid>
    </Grid>
  );
};

export default PreviousVisits;
