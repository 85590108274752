import React, { useEffect, useState } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import TopBarComponent from '../components/topBarComponent';
import SideBarComponent from '../components/sidebarComponent';
import style from './MainLayout.module.css';
import { setNavigateFunction } from '../services/nevigationService';
import { SelectChangeEvent, Typography } from '@mui/material';
import { getMyProfile } from '../services/apiService';
import { setTenantUrl } from '../services/httpService';
import { User } from '../types';
import { useTenant } from '../components/tenantProvider';
import disconnected from '../assets/images/disc.webp';
import ImageWithSkeleton from '../components/customImageComponent';

const MainLayout: React.FC = () => {

  const navigate = useNavigate();

  const [user, setUser] = React.useState<User | null>(null);
  const [clinic, setClinic] = React.useState<string>('');
  const { setTenantId } = useTenant();
  const [loading, setLoading] = useState(true);
  const handleChange = (event: SelectChangeEvent) => {
    setClinic(event.target.value);
  };

  // Fetch user profile when component mounts
  React.useEffect(() => {
    getMyProfile().then((response: User) => {
      setUser(response);
      localStorage.setItem('user', JSON.stringify(response));
    });
  }, []);

  // Set default clinic when the user data is loaded
  useEffect(() => {
    if (user?.clinic_department && user?.clinic_department.length > 0) {
      setClinic(user.clinic_department[0].clinic_id.toString()); // Set default clinic
    }
    setLoading(false); 
  }, [user]);


  useEffect(() => {
    if (process.env.REACT_APP_API_URL) {
      setTenantUrl(process.env.REACT_APP_API_URL);
    }
    setTenantId(clinic);
  }, [clinic, setTenantId]);
  
  // Set the navigate function in the service
  useEffect(() => {
    setNavigateFunction(navigate);
  }, [navigate]);

  if (loading) {
    return <div>Loading...</div>; 
  }
  return (
    <div className={style.main}>
      {user && <TopBarComponent handleChange={handleChange} clinic={clinic} user={user}/>}
      {clinic? <div className={style.content}>
        <div className={style.sidebar}>
          <SideBarComponent/>
        </div>
        <main className={style.main_page}>
          <div className={style.outletContent}>
            <Outlet context={clinic}/>  {/* This renders the child routes like Dashboard */}
          </div>
        </main>
      </div>: <div className={style.no_id}>
        <Typography variant='h6'>Sorry! Looks like you have not been assigned a clinic. Please <span>contact your Administrator</span>.</Typography>
        <ImageWithSkeleton src={disconnected} width={200} alt='no clinic'/>
      </div>}
    </div>
  );
};

export default MainLayout;