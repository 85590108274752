import React from 'react';
import { Link as RouterLink, useMatches, } from 'react-router-dom';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { BreadcrumbHandle } from '../../types'; 


const MuiBreadcrumbs: React.FC = () => {
  const matches = useMatches();

  const breadcrumbs = matches
    .filter((match) => (match.handle as BreadcrumbHandle)?.breadcrumb)
    .map((match, index) => {
      const handle = match.handle as BreadcrumbHandle;

      const breadcrumb = typeof handle.breadcrumb === 'function'
        ? handle.breadcrumb(
          Object.fromEntries(
            Object.entries(match.params).map(([key, value]) => [key, value ?? '']) // Fallback for undefined params
          )
        )
        : handle.breadcrumb;

      const isLast = index === matches.length - 2;

      if (isLast) {
        // Render last breadcrumb as plain text
        return (
          <Typography key={match.pathname} sx={{
            color: '#232323',
            fontFamily: 'Montserrat, sans-serif',
            fontSize: '1rem',
            fontStyle: 'normal',
            fontWeight: 700,
            lineHeight: '1.375rem', // 137.5%
          }}>
            {breadcrumb}
          </Typography>
        );
      }

      return (
        <Link
          key={match.pathname}
          component={RouterLink}
          to={match.pathname}
          underline="hover"
          color="inherit"
          sx={{
            color: '#232323',
            fontFamily: 'Montserrat, sans-serif',
            fontSize: '1rem',
            fontStyle: 'normal',
            fontWeight: 500,
            lineHeight: '1.375rem', // 137.5%
          }}
        >
          {breadcrumb}
        </Link>
      );
    });

  return (
    <Breadcrumbs separator={<NavigateNextIcon fontSize="medium" />} aria-label="breadcrumb">
      {breadcrumbs}
    </Breadcrumbs>
  );
};

export default MuiBreadcrumbs;