/* eslint-disable prefer-const */
import React, { useCallback } from 'react';
import { Avatar, Box, Typography } from '@mui/material';

function adjustColor(hex: string): string {
  // Function to convert hex to RGB
  const hexToRgb = (hex: string): { r: number; g: number; b: number } => {
    // Remove the leading # if present
    hex = hex.replace(/^#/, '');

    // Parse the r, g, b values
    let bigint = parseInt(hex, 16);
    let r = (bigint >> 16) & 255;
    let g = (bigint >> 8) & 255;
    let b = bigint & 255;

    return { r, g, b };
  };

  // Function to convert RGB back to hex
  const rgbToHex = (r: number, g: number, b: number): string => {
    const toHex = (n: number) => n.toString(16).padStart(2, '0');
    return `#${toHex(r)}${toHex(g)}${toHex(b)}`;
  };

  // Function to calculate brightness (using a formula for luminance perception)
  const calculateBrightness = ({ r, g, b }: { r: number; g: number; b: number }): number => {
    return (r * 299 + g * 587 + b * 114) / 1000;
  };

  // Function to lighten or darken the color
  const adjustBrightness = ({ r, g, b }: { r: number; g: number; b: number }, factor: number): { r: number; g: number; b: number } => {
    return {
      r: Math.min(Math.max(0, r + factor), 255),
      g: Math.min(Math.max(0, g + factor), 255),
      b: Math.min(Math.max(0, b + factor), 255),
    };
  };

  const rgb = hexToRgb(hex);
  const brightness = calculateBrightness(rgb);
  const isDark = brightness < 128; // Define dark/light threshold

  const adjustmentFactor = isDark ? 40 : -40; // Lighten dark colors, darken light colors

  const adjustedRgb = adjustBrightness(rgb, adjustmentFactor);

  return rgbToHex(adjustedRgb.r, adjustedRgb.g, adjustedRgb.b);
}
// Function to convert HSL to Hex (for unique border color based on ID)
function hslToHex(h: number, s: number, l: number): string {
  s /= 100;
  l /= 100;

  const c = (1 - Math.abs(2 * l - 1)) * s;
  const x = c * (1 - Math.abs((h / 60) % 2 - 1));
  const m = l - c / 2;
  let r = 0, g = 0, b = 0;

  if (0 <= h && h < 60) {
    r = c; g = x; b = 0;
  } else if (60 <= h && h < 120) {
    r = x; g = c; b = 0;
  } else if (120 <= h && h < 180) {
    r = 0; g = c; b = x;
  } else if (180 <= h && h < 240) {
    r = 0; g = x; b = c;
  } else if (240 <= h && h < 300) {
    r = x; g = 0; b = c;
  } else if (300 <= h && h < 360) {
    r = c; g = 0; b = x;
  }

  // Convert r, g, b from 0–1 to 0–255 and then to hex
  r = Math.round((r + m) * 255);
  g = Math.round((g + m) * 255);
  b = Math.round((b + m) * 255);

  const redHex = r.toString(16).padStart(2, '0');
  const greenHex = g.toString(16).padStart(2, '0');
  const blueHex = b.toString(16).padStart(2, '0');

  return `#${redHex}${greenHex}${blueHex}`;
}

interface AvatarListProps {
  avatars: { name: string; src: string; id: number, selected?: boolean }[];  // Array of avatars with names, image sources, and ids
  maxWidth: number;  // Maximum width for each avatar item
  onClick: (id: number) => void;  // onClick handler with the avatar id
}

const HorizontalAvatarList: React.FC<AvatarListProps> = ({ avatars, maxWidth, onClick }) => {
  const numberToColorHash = useCallback((num: number) => {
    // Use the golden ratio to spread out hue values more evenly
    const goldenRatio = 0.618033988749895; // Golden ratio conjugate
    const hue = (num * goldenRatio * 360) % 360; // Generate hue in the range [0, 360]
  
    // Convert hue to RGB using HSL model
     
    return hslToHex(hue, 65, 50); // 65% saturation, 50% lightness
  }, []);
  // Add "All" avatar at the beginning and filter out duplicate ids
  const updatedAvatars = [{ name: 'All', src: '', id: -1, selected: true }, ...avatars]
    .reduce((acc, current) => {
      const x = acc.find(item => item.id === current.id);
      if (!x) {
        return acc.concat([current]);
      } else {
        return acc;
      }
    }, [] as { name: string; src: string; id: number, selected?: boolean }[]);

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
        overflowX: 'auto',
        whiteSpace: 'nowrap',
        // width: '800px',
        maxWidth: `${maxWidth}px`,
        border: '1px solid #d2d2d2',
        borderRadius: '2rem',
        background: 'white',
      }}
    >
      {updatedAvatars.slice(0, 8).map((avatar) => (
        <Box
          key={avatar.id}
          sx={{
            display: 'inline-block',
            textAlign: 'center',
            marginRight: 2,
            maxWidth: '100px',
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            
            
          }}
          
        >
          <Avatar
            alt={avatar.name}
            src={avatar.src}
            sx={{
              width: 50,
              height: 50,
              bgcolor: avatar.src ? 'transparent' : numberToColorHash(avatar.id), // Background color if no image
              fontSize: '0.8rem', // Smaller font for the initials
              cursor: 'pointer',  // Make the avatar look clickable
              border: avatar.selected ? `3px solid ${adjustColor(numberToColorHash(avatar.id))}` : '3px solid #f7f7f7',  // Unique border color
              borderRadius: '50%', // Ensure the border is rounded
              padding: '1px', // Add padding when border is present,,
              boxShadow: '1px 5px 7px 0px #d5d2d2;'
              
            }}
            onClick={() => onClick(avatar.id)}
          >
            {!avatar.src && (avatar.name === 'All' ? avatar.name : avatar.name[0])} {/* Display first letter if no image */}
          </Avatar>
          <Typography variant="body1" sx={{ mt: 1, fontWeight: '700', fontSize: '0.7rem' }}>
            {avatar.name === 'All'? avatar.name: `${avatar.name}`}
          </Typography>
        </Box>
      ))}
    </Box>
  );
};

export default HorizontalAvatarList;