import React, { useState } from 'react';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert, { AlertProps } from '@mui/material/Alert';

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

interface CustomAlertComponentProps {
  error: string;
  severity: 'success' | 'warning' | 'info' | 'error';
  onClose?: () => void;
}

const AlertComponent: React.FC<CustomAlertComponentProps> = ({ error, severity, onClose }) => {
  const [open, setOpen] = useState(true); // State to manage Snackbar open/close

  const handleClose = (_event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      // Prevent closing when the user clicks outside the Snackbar (if desired)
      return;
    }
    setOpen(false); // Close the Snackbar
    if (onClose) onClose(); // Call the onClose callback if provided
  };

  return (
    <Snackbar
      style={{ top: '100px', left: '100px' }}
      open={open}
      autoHideDuration={4000} // 4 seconds auto-hide duration
      onClose={handleClose} // Handle the closing event
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
    >
      <Alert severity={severity} onClose={handleClose}>
        {error}
      </Alert>
    </Snackbar>
  );
};

export default AlertComponent;