import { BehaviorSubject } from 'rxjs';
import { Patient } from '../types';

const patientIdObj = new BehaviorSubject<string | null>(null);
const patientObj = new BehaviorSubject<Patient | null>(null);

export const setPatientId = (value: string) => {
  patientIdObj.next(value);
};
export const patientIdObservable = patientIdObj.asObservable();

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const setPatientDetails = (value:any)=>{
  patientObj.next(value);
};
export const patientDetailsObservable = patientObj.asObservable();

