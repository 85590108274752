import { Drawer, IconButton } from '@mui/material';
import React from 'react';
import logo from '../../assets/SVG/Clinic Logo.png';
import MenuIcon from '@mui/icons-material/Menu';
import styles from './drawer.module.css';
import SideBarComponent from '../sidebarComponent';

export const DrawerComponent: React.FC = () => {
  const [open, setOpen] = React.useState(false);
  const toggleDrawer = (newOpen: boolean) => () => {
    setOpen(newOpen);
  };
  return (
    <div >
      <IconButton onClick={toggleDrawer(true)} color="primary">
        <MenuIcon />
      </IconButton>
      <Drawer 
        sx={{ '& .MuiDrawer-paper': { width: '300px' } }} // Customize width here
        open={open} onClose={toggleDrawer(false)}>
        <div className={styles.main}>
          <div className={styles.logo_container}>
            <img src={logo} alt='logo' />
          </div>
          <SideBarComponent/>
        </div>
        
      </Drawer>
    </div>
  );
};