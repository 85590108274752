import React, { useEffect, useState } from 'react';
import { Grid, TextField, Button, ThemeProvider, Box, Autocomplete, IconButton, Dialog, DialogTitle, DialogContent, DialogActions, Typography, Tooltip } from '@mui/material';
import { theme } from '../../../../components/Medical_History_Forms/themeprovider';
import styles from './investigation.module.css';
import microScope from '../../../../assets/SVG/microscope 2.svg';
import addCircel from '../../../../assets/SVG/add-black.svg';
// import selectedStar from '../../../../assets/SVG/Star Yello.svg';
import maternityCare from '../../../../assets/SVG/maternity-care.svg';
import cart from '../../../../assets/SVG/shopping-cart.svg';
import CategoryDetails from './categoryDetails/CategoryDetails.index';
import closeCircle from '../../../../assets/SVG/close-black.svg';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { addInvestigation, addLabTestsCats, getLabTests, getLabTestsCats } from '../../../../services/apiService';
import closeCircleRed from '../../../../assets/SVG/close-circle.svg';
import { usePatientId } from '../../../../components/patientIdcontext';
import { ConfirmationModal } from '../../../../components/confirmationModal';
import {  CreateInvestigation } from '../../../../types';
import { useAlert } from '../../../../components/alertProvider';
import { useLoader } from '../../../../components/loaderProvider/loader';


interface Category{
  name: string;
  icon_url:string
};

interface LaboratoryTest {
  icon_url: string;
  id: number;
  identifier: string | number;
  name: string;
  remark?:string;
}


const Investigation = () => {



  const [commonOrders, setCommonOrders] = useState<LaboratoryTest[]>([]);
  const [categories, setCategories] = useState<LaboratoryTest[]>([]);
  const [selectedServices, setSelectedServices] = useState<LaboratoryTest[]>([]);
  const [selectedCategory, setSelectedCategory] = useState<Category | null>(null);
  const [isCategoryDetailVisible, setIsCategoryDetailVisible] = useState(false);
  const [showTable, setShowtable] = useState(false);
  const [open, setOpen] = useState(false);
  const [categoryName, setCategoryName] = useState('');
  const [servicesbyCats, SetservicesbyCats] = useState<LaboratoryTest[]>([]);;
  const [isExpanded, setIsExpanded] = useState(false);
  const [selectederviceCount, setSelectedServiceCount] = useState('');
  const [openModal, setOpenModal] = React.useState(false);
  const [searchorder, setSearchorder] = useState('');
  const [selectedCustomTests, setSelectedCustomTests] = useState<LaboratoryTest[]>([]);
  const [diagnosis, setDiagnosis] = useState<LaboratoryTest[]>([]);
  const [selectedDiagnosis, setSelectedDiagnosis] = useState<LaboratoryTest | null>(null); // Type updated to handle null
  const [inputValue, setInputValue] = useState(''); // State for input value
  const [error, setError] = useState(false); 


  const patientId = usePatientId();
  const { addAlert } = useAlert();
  const { showLoader, hideLoader } = useLoader();


  const toggleAccordion = () => {setIsExpanded(prev => !prev);};
  const handleClose = () => {setOpen(false);};
  const handleInputChange = (event: { target: { value: React.SetStateAction<string>; }; }) => {
    setCategoryName(event.target.value);
    setError(false);
  };
  const handleDatafromCategory = (data:boolean) => {setShowtable(data);};
  const onBack = () => {setIsCategoryDetailVisible(false);};
  const handleAddService = (order: LaboratoryTest) =>{
    const isOrderAlreadyAdded = selectedServices.some((service) => service.id === order.id);

    if (isOrderAlreadyAdded) {
      addAlert('This service is already added.', 'info');
    } else {
      setSelectedServices((prev) => [...prev, order]);
    }
  };
  const handleRemoveService = (index:number) => {setSelectedServices((prev) => prev.filter((_, i) => i !== index));};
  const handleCloseModal = () => {setOpenModal(false);};
  const handleOpenModal=()=>{setOpenModal(true);};

  useEffect(() => {
    getLabTestsCats('').then((res) => {setCategories(res);});
  }, [setCategories, open]);


  const handleSearch = (searchValue:string) => {

    const query = `?name__icontains=${searchValue}`;
    getLabTests(query).then((res) => {
      setCommonOrders(res.objects);
    });
    setSearchorder(searchValue);
  };

  const handleCategoryClick = (category:Category) => {
    setSelectedCategory(category);
    setIsCategoryDetailVisible(true);
    getLabTests(`?categories__name__icontains=${category.name}`).then((res) => {
      SetservicesbyCats(res.objects);
      setSelectedServiceCount(res.meta.total_count);
    });

  };

  const handleSave = () =>{
    showLoader();
    const convertedData = selectedServices.map(test => ({
      patient: patientId,
      laboratory_test: test.id,
      remark: test.remark
    }));

    const sendInvestigation = async (data:CreateInvestigation) => {
      return await addInvestigation(data);
    };

    Promise.all(convertedData.map(data => sendInvestigation(data)))
      .then(() => {
        addAlert('Order Placed Successfully', 'success');
        setSelectedServices([]);
        setIsExpanded(false);
        hideLoader();

      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.error('An error occurred:', error);
        addAlert('An error occurred while saving the tests. Please try again.', 'error');
      });
  };

  const handleRemarkChange = (index:number, newRemark:string) => {
    const updatedServices = [...selectedServices];
    updatedServices[index].remark = newRemark;
    setSelectedServices(updatedServices);
  };





  const handleSearchcustom = (searchValue: string) => {
    const query = `?name__icontains=${searchValue}`;
    getLabTests(query).then((res) => {
      setDiagnosis(res.objects);
    });
  };

  const handleSelectionChange = (value: LaboratoryTest | null) => {
  // Check if value is not null before proceeding
    if (value) {
      setSelectedDiagnosis(value);
      setSelectedCustomTests((prevSelectedCustomTests) => [...prevSelectedCustomTests, value]);
    } else {
      setSelectedDiagnosis(null);
    }
    setInputValue('');
  };

  const handleDeleteService = (serviceToDelete: LaboratoryTest) => {
    // Remove the service from selectedCustomTests
    setSelectedCustomTests((prevSelectedCustomTests) =>
      prevSelectedCustomTests.filter((order) => order.identifier !== serviceToDelete.identifier)
    );
  };
  
  const createCategory = () => {
    showLoader();
    const category = selectedCustomTests.map(item => item.id);
    
    const data ={
      name:categoryName,
      custom_tests:category
    };

    addLabTestsCats(data)
      .then((res) => {
      // eslint-disable-next-line no-console
        console.log('data added successfully', res);
        setSelectedCustomTests([]); 
        setCategoryName('');
        setOpen(false); 
        addAlert('Category created Successfully.', 'success'); 
        hideLoader();
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.error('Error adding category:', error);
        addAlert('Failed to create category. Please try again.', 'error'); 
      })
      .finally(() => {
        hideLoader();
      });
    
    setError(false);
  };
  const handleSubmit = () => {
    if (!categoryName.trim()) {
      setError(true); // Set error if category name is empty
      return; // Prevent form submission
    }

    // If category name is valid, proceed with the form submission
    createCategory();
  };


  return (

    <div>
      {showTable ? (
        <CategoryDetails  onData={handleDatafromCategory} />
      ) : (
        <Box>
          <ThemeProvider theme={theme}>
            <div>
              <div>
                <div className='mt-3'>
                  <Grid container spacing={2} alignItems="center">
                    {/* Autocomplete Search Field */}
                    <Grid item xs={7}>
                      <TextField
                        InputProps={{
                          style: {
                            height: '50px', // Adjust this value as needed
                          },
                        }}
                        InputLabelProps={{
                          style: {
                            lineHeight: '30px', // Match this with the height of the TextField
                          },
                        }}
                        id="outlined-basic" label="Search "  variant="outlined" fullWidth
                        onChange={(e) => handleSearch(e.target.value)}
                        placeholder="Search by code or test name" />
                    </Grid>
                    <Grid item xs={5}>
                      <div className="d-flex justify-content-end" style={{ position: 'relative' }}>
                        {selectedServices.length > 0 && (
                          <span
                            style={{
                              position: 'absolute',
                              top: '6px',
                              right: '10px',
                              width: '20px',
                              height: '20px',
                              backgroundColor: '#f00',
                              color: '#fff',
                              borderRadius: '50%',
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'center',
                              fontSize: '12px',
                              fontWeight: 'bold',
                              zIndex: 1
                            }}
                          >
                            {selectedServices.length}
                          </span>
                        )}
                        <Button
                          onClick={toggleAccordion}
                          style={{
                            width: '55px',
                            height: '55px',
                            border: 'solid 1px #E2E3E5',
                            borderRadius: '15px',
                            padding: '0px'
                          }}
                        >
                          <img src={cart} alt="Cart Icon" />
                        </Button>
                      </div>
                    </Grid>
                  </Grid>
                </div>

                {/* Other Box content */}
                <Box className='mt-4'>
                  {/* Common Orders Section */}
                  <p className={`${styles.commonOrder}`}>Common Tests</p>
                  <Box className='mt-3'>
                    {commonOrders.length === 0 ? (
                    // Show this message when no common orders are found
                      <Box display="flex" justifyContent="center" alignItems="center" p={2}>
                        <p>No Test found <span style={{fontWeight:700}}>{searchorder}</span></p>
                      </Box>
                    ) : (
                    // Show the list of orders when there are common orders
                      <Grid container spacing={2}>
                        {commonOrders.map((order, index) => {
                          const isOrderAdded = selectedServices.some((service) => service.id === order.id);

                          return (
                            <Grid item xs={12} sm={6}  md={4} lg={3} xl={2.4} key={index}>
                              <Box
                                display="flex"
                                flexDirection="column"
                                justifyContent="space-between"
                                p={2}
                                m={1}
                                borderRadius="8px"
                                boxShadow="3px 3px 8px rgba(0, 0, 0, 0.1)"
                                bgcolor="#fff"
                              >
                                <Box display="flex" justifyContent="start" alignItems="center">
                                  <img src={microScope} alt="test Icon" />
                                  <Box ml={2}>
                                    <Tooltip title={order.name} arrow>
                                      <p className={`${styles.content} ${styles.truncatedTitle1}`}>{order.name}</p>
                                    </Tooltip>
                                    <p>{order.identifier}</p>
                                  </Box>
                                </Box>
                                <hr />
                                <Grid container justifyContent="space-between" alignItems="center">
                                  <Grid item>
                                    {/* <p>{order.category}</p> */}
                                  </Grid>
                                  <Grid item>
                                    <button
                                      onClick={() => handleAddService(order)}
                                      style={{
                                        padding: '5px 10px',
                                        border: 'none',
                                        borderRadius: '7px',
                                        fontSize: '16px',
                                        fontWeight: '700',
                                        backgroundColor: isOrderAdded ? '#F4F4F4' : '#F4F4F4', // Gray if added, blue otherwise
                                        color: isOrderAdded ? 'rgb(88 88 88)' : '#232323', // Darker text if added
                                      }}
                                      disabled={isOrderAdded} // Disable if added
                                    >
                                      {!isOrderAdded && (
                                        <img src={addCircel} alt="Add Icon" style={{ width: '22px', height: '22px' }} />
                                      )}

                                      {isOrderAdded ? 'Added' : ' Add'}
                                    </button>
                                  </Grid>
                                </Grid>
                              </Box>
                            </Grid>
                          );
                        })}
                      </Grid>
                    )}
                  </Box>

                </Box>

                <Box className='mt-5' >
                  <Grid container justifyContent="space-between">
                    <p className={`${styles.commonOrder}`}>Categories</p>
                    <button
                      onClick={() => setOpen(true)}
                      style={{
                        padding: '10px',
                        border: 'none',
                        borderRadius: '7px',
                        fontSize: 'larger',
                        fontWeight: '700',
                        backgroundColor: '#F4F4F4',
                        color:'#232323',
                      }}
                      
                    >
                    
                      <img src={addCircel} alt="Add Icon" style={{ width: '24px', height: '24px' }} />

                     &nbsp; Create Category
                    </button>
                  </Grid>
                  <Box className='mt-3'>
                    <Grid container spacing={2}>
                      {/* Loop through commonOrders array to create dynamic grid items */}
                      {categories.map((order, index) => (
                        <Grid item xs={12} sm={6} md={4} lg={3} xl={2.4} key={index}>
                          <Box
                            display="flex"
                            flexDirection="column"
                            justifyContent="space-between"
                            p={2}
                            m={1}
                            border="1px solid #e6e7e8"
                            borderRadius="8px"
                            onClick={() => handleCategoryClick(order)}
                            boxShadow="3px 3px 8px rgba(0, 0, 0, 0.1)"
                            bgcolor="#fff"
                          >
                            <Grid container justifyContent="space-between" alignItems="center">
                              <Grid display="flex" justifyContent="start">
                                <Grid>
                                  <img src={maternityCare} alt="Maternity Care" />
                                </Grid>
                                <Grid item marginLeft={2}>
                                  <Tooltip title={order.name} arrow>
                                    <p className={`${styles.content1} ${styles.truncatedTitle2}`}>{order.name}</p>
                                  </Tooltip>
                                </Grid>
                              </Grid>
                              {/* <Grid item>
                                <button style={{ padding: '7px 10px', marginLeft: '10px', border: 'none', borderRadius: '7px' }}>
                                  <img src={selectedStar} alt="Add Icon" style={{ width: '24px', height: '24px' }} />
                                </button>
                              </Grid> */}
                            </Grid>
                          </Box>
                        </Grid>
                      ))}
                    </Grid>
                    {isCategoryDetailVisible && selectedCategory  && ( // Conditionally render CategoryDetails if categoryName is true

                      <Box className='mt-5 categoryDetails'
                        borderRadius="8px"
                        border="1px solid #CFD1D4"
                        boxShadow="0 4px 8px rgba(0, 0, 0, 0.1)"
                        bgcolor="#f9f2f291"
                        padding={3}
                      >
                        <Box>
                          <Grid item xs={12} display="flex" justifyContent="space-between">

                            <p className={`${styles.commonOrder}`}>
                              {selectedCategory.name} ({selectederviceCount})
                            </p>
                            <IconButton onClick={onBack}>
                              <img src={closeCircle} alt="Back" style={{ width: '2rem', height: '2rem' }} />
                            </IconButton>
                          </Grid>
                        </Box>
                        <Box>
                          <Box >
                            <Grid container spacing={2}>
                              {servicesbyCats.map((order, index) => {
                                const isOrderAdded = selectedServices.some((service) => service.id === order.id);
                                return(

                                  <Grid item xs={12} sm={6} md={4} lg={3} xl={2.4}  key={index}>
                                    <Box
                                      display="flex"
                                      flexDirection="column"
                                      justifyContent="space-between"
                                      p={2}
                                      borderRadius="8px"
                                      boxShadow="0 4px 8px rgba(0, 0, 0, 0.1)"
                                      bgcolor="#fff"
                                    >
                                      <Box display="flex" justifyContent="start" alignItems="center">
                                        <img src={microScope} alt="Microscope Icon" />
                                        <Box ml={2}> 
                                          <Tooltip title={order.name} arrow>
                                            <p className={`${styles.content} ${styles.truncatedTitle3}`}>{order.name}</p>
                                          </Tooltip>
                                          <p>{order.identifier}</p>
                                        </Box>
                                      </Box>
                                      <hr />
                                      <Grid container justifyContent="space-between" alignItems="center">
                                        <button
                                          onClick={() => handleAddService(order)}
                                          style={{
                                            padding: '5px 10px',
                                            border: 'none',
                                            borderRadius: '7px',
                                            fontSize: '16px',
                                            fontWeight: '700',
                                            backgroundColor: isOrderAdded ? '#F4F4F4' : '#F4F4F4', // Gray if added, blue otherwise
                                            color: isOrderAdded ? 'rgb(88 88 88)' : '#232323', // Darker text if added
                                          }}
                                          disabled={isOrderAdded} // Disable if added
                                        >
                                          {!isOrderAdded && (
                                            <img src={addCircel} alt="Add Icon" style={{ width: '24px', height: '24px' }} />
                                          )}

                                          <span className={`${styles.fade} ${isOrderAdded ? styles.fadeActive : ''}`}>
                                            {isOrderAdded ? 'Added' : ' Add'}
                                          </span>
                                        </button>

                                        {/* <button style={  {padding: '7px 10px',marginLeft: '10px', border: 'none', borderRadius: '7px' }}>
                                          <img src={selectedStar} alt="Add Icon" style={{ width: '24px', height: '24px' }} />
                                        </button> */}
                              
                                      </Grid>
                                    </Box>
                                  </Grid>
                                );})}
                            </Grid>
                          </Box>
                        </Box>
                      </Box>
                    )}
                  </Box>
                </Box>





                {/* Additional sections here */}
                <Box className='my-5'>
                  <p className={styles.prevInvest} style={{width:'fit-content'}} onClick={() => setShowtable(true)}>
                Previous Investigation
                  </p>
                </Box>
              </div>

            </div>


            <Dialog open={open} onClose={handleClose}
              PaperProps={{
                style: {
                  maxWidth: '1000px', // Set the desired width
                  minWidth:'600px',
                  borderRadius: '25px', // Set the desired border radius
                },
              }}
            >
              <DialogTitle>
                <Grid container spacing={2}>
                  <Grid item xs={9} display="flex" justifyContent="start">
                    <p className={styles.commonOrder}>Create Custom Category</p>
                  </Grid>

                  <Grid item xs={3} display="flex" justifyContent="end" >
                    <div>
                      <Button onClick={handleClose}>
                        <img src={closeCircle} alt="Close Icon"  style={{ width: '24px', height: '24px' }}/>
                      </Button>
                    </div>
                  </Grid>
                </Grid>
              </DialogTitle>
              <DialogContent>
                <Box>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <TextField
                        label="Name of Category"
                        value={categoryName}
                        onChange={handleInputChange} // Update state on change
                        fullWidth
                        variant="outlined"
                        margin="normal"
                        required
                        error={error} // Show error style if categoryName is empty
                        helperText={error ? 'Category Name is required' : ''} 
                      />
                    </Grid>

                    {/* Autocomplete Search Field */}
                    <Grid item xs={12}>

                      <Autocomplete
                        disablePortal
                        fullWidth
                        options={diagnosis}
                        value={selectedDiagnosis} // Bind the selected value
                        inputValue={inputValue} // Bind the input value
                        onChange={(e, value) => handleSelectionChange(value)}
                        onInputChange={(e, value) => {
                          setInputValue(value); // Update input value state
                          handleSearchcustom(value); // Call your search function
                        }}
                        getOptionLabel={(option) => option.name || ''}
                        renderInput={(params) => <TextField {...params} label="Search" />}
                      />

                    </Grid>


                  </Grid>

                </Box>

                <Box className='mt-3' sx={{ maxHeight: '250px', overflowY: 'auto' }}>
                  <Grid container spacing={2}>
                    {selectedCustomTests.map((order, index) => (
                      <Grid item xs={12} sm={6} md={4} key={index}>
                        <Box
                          display="flex"
                          flexDirection="column"
                          justifyContent="space-between"
                          p={2}
                          borderRadius="8px"
                          boxShadow="0 4px 8px rgba(0, 0, 0, 0.1)"
                          bgcolor="#fff"
                        >
                          <Box display="flex" justifyContent="start" alignItems="center">
                            <img src={microScope} alt="Microscope Icon" />
                            <Box ml={2}>
                              <p className={`${styles.content}`}>{order.name}</p>
                              <p>{order.identifier}</p>
                            </Box>
                          </Box>
                          <hr />
                          <Grid container justifyContent="space-between" alignItems="center">
                            <Grid item>
                              {/* <p>{order.category}</p> */}
                            </Grid>
                            <Grid item>
                              <Button
                                variant="contained"
                                sx={{
                                  backgroundColor: 'rgb(240,240,240)',
                                  fontFamily: 'Montserrat',
                                  fontSize: '14px',
                                  fontWeight: 700,
                                  textAlign: 'left',
                                  color: '#505050',
                                  '&:hover': {
                                    backgroundColor: 'rgb(200,200,200)',
                                  },
                                }}
                                onClick={() => handleDeleteService(order)}
                              >
                                <img src={closeCircleRed} alt='delete'></img>
                              </Button>

                            </Grid>
                          </Grid>
                        </Box>
                      </Grid>
                    ))}
                  </Grid>
                </Box>


              </DialogContent>
              <DialogActions>

                <div style={{  display: 'flex', justifyContent: 'end', width: '100%' }}>
                  <button
                    onClick={()=>{setOpen(false);}}
                    style={{
                      padding: '5px 10px',
                      width:'100px',
                      border: 'none',
                      borderRadius: '7px',
                      fontSize: 'larger',
                      fontWeight: '700',
                      marginRight:'20px',
                      backgroundColor: '#F3F3F3',
                      color: '#505050',
                    }}
                  >
      Close
                  </button>
                  <button
                    onClick={handleSubmit} 
                    style={{
                      padding: '5px 10px',
                      width:'100px',
                      border: 'none',
                      borderRadius: '7px',
                      fontSize: 'larger',
                      fontWeight: '700',
                      backgroundColor: '#505050',
                      color: '#FFF',
                    }}
                  >
      Confirm
                  </button>
                </div>
              </DialogActions>
            </Dialog>


            {(selectedServices.length > 0 || isExpanded) && (
              <Box
                sx={{
                  position: 'fixed',
                  bottom: '20px',
                  right: '20px',
                  width: '440px',
                  maxHeight: '400px',
                  padding: '16px',
                  borderRadius: '25px',
                  boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
                  backgroundColor: '#E2E3E5',
                  zIndex: 1000,
                }}
              >
                {/* Accordion-like clickable title */}
                <Box
                  onClick={toggleAccordion}
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    cursor: 'pointer',
                    padding: '10px',
                  }}
                >
                  <Typography
                    sx={{
                      fontFamily: 'Montserrat',
                      fontSize: '18px',
                      fontWeight: 700,
                    }}
                  >
        Your cart
                  </Typography>
                  <ArrowDropDownIcon
                    sx={{ transform: isExpanded ? 'rotate(180deg)' : 'rotate(0deg)' }}
                  />
                </Box>

                {/* Expandable content */}
                {isExpanded && (
                  <Box
                    sx={{
                      padding: '16px',
                      border: '1px solid #ccc',
                      borderRadius: '15px',
                      marginTop: '8px',
                      maxHeight: '300px',
                      backgroundColor: '#fff',
                      overflow: isExpanded ? 'auto' : 'hidden',
                    }}
                  >
                    {selectedServices.map((service, index) => (
                      <Box
                        key={index}
                        display="flex"
                        flexDirection="column"
                        justifyContent="space-between"
                        p={2}
                        borderRadius="8px"
                        border="1px solid grey"
                        boxShadow="0 4px 8px rgba(0, 0, 0, 0.1)"
                        bgcolor="#fff"
                        m={2}
                      >
                        <Grid container spacing={2}>
                          <Grid item xs={10} display="flex" justifyContent="start">
                            <p>
                              {service.name} ({service.identifier})
                            </p>
                          </Grid>
                          <Grid item xs={2} display="flex" justifyContent="end">
                            <Button onClick={() => handleRemoveService(index)}>
                              <img
                                src={closeCircleRed}
                                alt="Close Icon"
                                style={{ width: '20px', height: '20px' }}
                              />
                            </Button>
                          </Grid>
                          <Grid item xs={12}>
                            <TextField
                              label="remark"
                              type="textarea"
                              multiline
                              fullWidth
                              rows={1}
                              value={service.remark || ''}
                              name={`remarks_${index}`}
                              variant="outlined"
                              onChange={(e) => handleRemarkChange(index, e.target.value)}
                            />
                          </Grid>
                        </Grid>
                      </Box>
                    ))}

                    <div className="d-flex justify-content-end">
                      <button
                        onClick={handleOpenModal}
                        style={{
                          padding: '5px 10px',
                          border: 'none',
                          borderRadius: '7px',
                          fontSize: 'larger',
                          fontWeight: '700',
                          marginRight: '15px',
                          backgroundColor: '#505050',
                          color: '#FFF',
                        }}
                        disabled={selectedServices.length === 0}
                      >
                        Proceed
                        {/* Proceed */}
                      </button>
                    </div>
                  </Box>
                )}
              </Box>
            )}



          </ThemeProvider>

          <ConfirmationModal
            open={openModal}
            handleClose={handleCloseModal}
            onConfirm={handleSave}
            type={'save'}
            title='Place Order'
            contentMessage='Are you sure you want to order the selected tests?'
          />
        </Box>
      )}
    </div>


  );
};

export default Investigation;
