/**
 * Validates an email address format.
 * @param {string} email - The email address to validate.
 * @returns {boolean} - Returns true if valid, false otherwise.
 */
export const validateEmail = (email: string | null | undefined): boolean => {
  if (!email || email.trim() === '') {
    return false; // Return false if email is empty, null, or undefined
  }

  const emailRegex = /^[^\s@]+@[^\s@]+\.[a-zA-Z]{2,}$/;
  return emailRegex.test(email);
};


/**
 * Convert a string to title case.
 * @param {string} str - The input string.
 * @returns {string} - The title cased string.
 */
export const toTitleCase = (str: string | null | undefined): string => {
  // If the string is null or undefined, return an empty string
  if (str == null || str.trim() === '') {
    return '';
  }

  // Convert to title case
  return str
    .toLowerCase()
    .split(' ')
    .filter(word => word.length > 0) // Filter out any empty words from multiple spaces
    .map(word => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');
};

/**
 * Trim whitespace from both ends of a string.
 * @param {string | null | undefined} str - The input string (can also be null or undefined).
 * @returns {string} - The trimmed string, or an empty string if the input is null or undefined.
 */
export const trimString = (str: string | null | undefined): string => {
  // Return empty string if the input is null or undefined
  return str ? str.trim() : '';
};


/**
 * Check if a string is a valid email format.
 * @param {string} email - The email string to validate.
 * @returns {boolean} - True if valid, false otherwise.
 */
export const isValidEmail = (email: string): boolean => {
  const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return regex.test(email);
};

/**
 * Checks if the given date is in the past compared to the current date and time.
 * 
 * @param date - The date to check. It can be a valid Date object or a date string.
 * @returns True if the date is in the past, otherwise false.
 * @throws Error if the provided date is invalid.
 */
export const isDateInPast = (date: Date | string): boolean => {
  let parsedDate: Date;

  if (typeof date === 'string') {
    parsedDate = new Date(date);
  } else if (date instanceof Date) {
    parsedDate = date;
  } else {
    throw new Error('Invalid date provided. Must be a Date object or a date string.');
  }

  const now = new Date();
  return parsedDate.getTime() < now.getTime();
};

export const timeDifference = (startTime: string, endTime: string) => {
  const start = new Date(`1970-01-01T${startTime}:00`);
  const end = new Date(`1970-01-01T${endTime}:00`);
  const diffInMinutes = (Number(end) - Number(start)) / (1000 * 60);

  return diffInMinutes;
};


export const getMaxDateForMonthInput = () => {
  return new Date().toISOString().slice(0, 7); // Returns the current year and month in YYYY-MM format
};

 

/**
   * Get today's date in YYYY-MM-DD format (for date input max).
   * @returns The current date in YYYY-MM-DD format.
   */
export const getMaxDate = ()=> {
  return new Date().toISOString().split('T')[0]; // Max date is today's date
};

// Checking if the appointment date has passed or not
export const checkDatePassed = (givenDateStr: string) => {
  if (!givenDateStr) return false;
  
  let givenDate;
    
  if (givenDateStr.includes('-') && givenDateStr.split('-')[0].length === 2) {
    const [day, month, year] = givenDateStr.split('-').map(Number);
    givenDate = new Date(year, month - 1, day);
  } else {
    givenDate = new Date(givenDateStr);
  }
  
  const today = new Date();
  today.setHours(0, 0, 0, 0);
  
  return givenDate <= today;
};

/**
 * Format a date input to 'dd-mm-yyyy'.
 * @param {Date | string} dateInput - The date input to format.
 * @returns {string} The formatted date string in 'dd-mm-yyyy' format.
 */
export const getDateInDDMMYYYY = (dateInput: Date | string): string => {
  if (!dateInput) return '';

  let date;

  if (dateInput instanceof Date) {
    date = dateInput;
  } else if (typeof dateInput === 'string') {
    date = new Date(dateInput);
  } else {
    return dateInput; // Return
  }

  if (isNaN(date.getTime())) return dateInput.toString(); // Return the input as-is

  return date.toLocaleDateString('en-GB');
};
