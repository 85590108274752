/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
import styles from './header_Profile.module.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import Icon from '../../assets/SVG/Icon.svg';
import messagetext from '../../assets/SVG/message-text.svg';
import upload from '../../assets/SVG/document-upload.svg';
import printer from '../../assets/SVG/printer.svg';
import React, { useState, useEffect, useCallback, useMemo, useRef } from 'react';
import { getPatientDetails } from '../../services/apiService';
import { useLoader } from '../loaderProvider/loader';
import  defaultFemale from '../../assets/SVG/Default Profile - Female.svg';
import  defaultMale from '../../assets/SVG/Default Profile - Male.svg';
import ImageWithSkeleton from '../customImageComponent';
import { toTitleCase } from '../../utils/commonUtils';
import { patientIdObservable, setPatientDetails, setPatientId } from '../../services/sharedService';



export default function HeaderProfile({ onData }:any){

  const [selectedImg, setSelectedImg] = useState<number | null>(null);
  const [AllPatients, setAllPatient] = useState<any>(null);
  const [selectedPatient, setselectedPatient] = useState<any>(null);
  const [isStimLoad, setIsStimLoad] = useState<boolean>(false);
  const { showLoader, hideLoader } = useLoader();
  const [age, setAge] = useState<number | null>(null);
  const [StoredpatientId, setstoredPatientId] = useState<string |null>(null);


  const calculateAge = (dateOfBirth: string) => {
    const birthDate = new Date(dateOfBirth);
    const today = new Date();
    const age = today.getFullYear() - birthDate.getFullYear();
    const month = today.getMonth() - birthDate.getMonth();
    if (month < 0 || (month === 0 && today.getDate() < birthDate.getDate())) {
      return age - 1; 
    }
    return age;
  };
  

  const changeBorderColor = useCallback((patient: any) => {
    setSelectedImg(patient.id);
    setselectedPatient(patient);
    onData(patient);
    const newAge = patient.date_of_birth ? calculateAge(patient.date_of_birth) : null;
    setAge(newAge);
    setPatientId(`${patient.id}`);
    setPatientDetails(patient);
  }, [onData,]);

  const onDataRef = useRef(onData);
  useEffect(() => {
    onDataRef.current = onData;
  }, [onData]);

  const notifyOnData = useCallback((data:any) => {
    onDataRef.current(data);
  }, []);

  useEffect(() => {
    const subscription = patientIdObservable.subscribe((value) => {
      setstoredPatientId(value);

    });

    return () => subscription.unsubscribe();
  }, []);
  useEffect(() => {
  
    showLoader();
    
    if (StoredpatientId) {
      const fetchData = async () => {
        try {
          const response = await getPatientDetails(StoredpatientId);
          const { partner, addresses, ...rest } = response;
  
          const patientData = [];
          patientData[0] = { ...rest }; 
          if (partner) {
            patientData[1] = partner; 
          }

          const selectedPatient = patientData[0];

          setAllPatient(patientData);
          
          setSelectedImg(selectedPatient.id);
          setselectedPatient(selectedPatient);
          setPatientId(`${selectedPatient.id}`);
          setPatientDetails(selectedPatient);
          notifyOnData(selectedPatient); 
          const newAge = selectedPatient.date_of_birth ? calculateAge(selectedPatient.date_of_birth) : null;
          setAge(newAge);
        } catch (error) {
          // eslint-disable-next-line no-console
          console.error('Error fetching patient details:', error);
        } finally {
          hideLoader();
        }
      };
  
      fetchData();
    }
  }, [showLoader, hideLoader, notifyOnData, StoredpatientId]); 

  return (
    <div className={'w-100 pt-4'}>
      {AllPatients && Array.isArray(AllPatients) &&
      <div className={styles.main}>
        <div className={styles.mainPics}>
          {AllPatients.map((img: any, index: number) => (
            <div className={styles.pics} key={index}>
              <div className={selectedImg === img.id  ? styles.main_pc_dv : styles.main_pc_dvvv} >
                <ImageWithSkeleton 
                  src={img.photo_url ? img.photo_url : img.gender === 'MALE' ? defaultMale : defaultFemale}
                  alt='pc' 
                  height={'6rem'} 
                  width={'6rem'}
                  borderRadius={'50%'} 
                  containerStyle={{
                    marginTop: '-3.5rem',
                  }}
                  hideOverflow={false}
                  onClick={() => changeBorderColor(img)}

                />
              </div>
            </div>
          ))}
        </div>
        <div className={styles.primary}>
          <div className={styles.name}>
            <span>Patient Name</span>
            <label>
              {selectedPatient?.prefix ? `${toTitleCase(selectedPatient.prefix)} ` : ''}
              {selectedPatient?.first_name ? `${toTitleCase(selectedPatient.first_name)} ` : ''}
              {selectedPatient?.last_name ? toTitleCase(selectedPatient.last_name) : ''}
            </label>
          </div>
          <div className={styles.primary_item}>
            <span>Age</span>
            <label>{age !== null ? age : '-'}</label> 
          </div>
          <div className={styles.primary_item}>
            <span>Sex Assigned At Birth</span>
            <label>{toTitleCase(selectedPatient.gender)}</label>
          </div>
          <div className={styles.primary_item}>
            <span>MRN</span>
            <label>{selectedPatient.medical_record_no|| '-'}</label>
          </div>
          <div className={styles.primary_item}>
            <span>Last Modified</span>
            <label>
              {selectedPatient?.updated_on
                ? new Date(selectedPatient.updated_on).toLocaleDateString('en-GB')
                : '-'}
            </label>

          </div>
        </div>
      </div>
      }
    </div>


  );
};
