import React, { createContext, useContext, useState, ReactNode, useCallback } from 'react';

// Define the context type
interface TenantContextType {
  tenantId: string;
  setTenantId: (id: string) => void;
}

// Create the TenantContext
const TenantContext = createContext<TenantContextType | undefined>(undefined);

// Custom hook to use the TenantContext
export const useTenant = (): TenantContextType => {
  const context = useContext(TenantContext);
  if (!context) {
    throw new Error('useTenant must be used within a TenantProvider');
  }
  return context;
};

// TenantProvider component to wrap the app
export const TenantProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [tenantId, setTenantIdState] = useState<string>(''); // Default tenant ID, can be an empty string or a default value

  // Memoize setTenantId to avoid re-creation on each render
  const setTenantId = useCallback((id: string) => {
    setTenantIdState(id);
  }, []);

  return (
    <TenantContext.Provider value={{ tenantId, setTenantId }}>
      {children}
    </TenantContext.Provider>
  );
};