import { Skeleton } from '@mui/material';
import React from 'react';

const CalendarLoader: React.FC = () => {
  return (
    <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'space-between'}}>
      <Skeleton variant="rectangular" sx={{ fontSize: '1rem', width: '100%'}} height={100}/>
      <Skeleton variant="rectangular" sx={{ fontSize: '1rem', width: '100%'}} height={100}/>
      <Skeleton variant="rectangular" sx={{ fontSize: '1rem', width: '100%'}} height={100}/>
      <Skeleton variant="rectangular" sx={{ fontSize: '1rem', width: '100%'}} height={100}/>
      <Skeleton variant="rectangular" sx={{ fontSize: '1rem', width: '100%'}} height={100}/>
    </div>
  );
};

export default CalendarLoader;