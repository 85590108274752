import { createTheme} from '@mui/material';

// Create a custom theme
export const theme = createTheme({
  palette: {
    primary: {
      main: '#232323', // Primary color
      contrastText: '#FFFFFF', // Text color for primary button
    },
    secondary: {
      main: '#9E9E9E', // Secondary color
      contrastText: '#505050', // Text color for secondary button
    },
    vidaiOrange: '#E17E61',
  },
  typography: {
    fontFamily: 'Nunito, sans-serif', // Default font for everything else
    h1: {
      fontFamily: 'Montserrat, sans-serif', // Font for headings
    },
    h2: {
      fontFamily: 'Montserrat, sans-serif',
    },
    h3: {
      fontFamily: 'Montserrat, sans-serif',
    },
    h4: {
      fontFamily: 'Montserrat, sans-serif',
    },
    h5: {
      fontFamily: 'Montserrat, sans-serif',
    },
    h6: {
      fontFamily: 'Montserrat, sans-serif',
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'none', // Ensure text is not uppercase by default
          fontFamily: 'Nunito, sans-serif',
          borderRadius: '10px', // Apply border-radius to all buttons
          cursor: 'pointer',
        },
        containedPrimary: {
          backgroundColor: '#505050', // Primary button background
          color: '#FFFFFF', // Primary button text
          cursor: 'pointer',
        },
        containedSecondary: {
          backgroundColor: '#FFFFFF', // Secondary button background
          color: '#505050', // Secondary button text
          border: '1px solid #505050', // Optional: Add border for secondary button
          cursor: 'pointer',
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          borderRadius: '10px', // Apply border-radius to all text fields
          '& .MuiOutlinedInput-root': {
            borderRadius: '10px', // Ensure the input itself has rounded borders
            cursor: 'pointer',
          },
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        root: {
          borderRadius: '10px', // Apply border-radius to all select fields
          '& .MuiOutlinedInput-root': {
            borderRadius: '10px', // Ensure the input itself has rounded borders
            cursor: 'pointer',
          },
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          borderRadius: '10px', // Apply border-radius to all outlined inputs
          cursor: 'pointer',
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          cursor: 'pointer', // Apply pointer cursor to all input fields
        },
      },
    },
  },
});

