/* eslint-disable no-console */
import { format, addDays, setHours, setMinutes, setSeconds, isSameDay, isBefore, isEqual } from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';

export interface DateRange {
  date: string; // Day in 'yyyy-MM-dd' format (UTC)
  start: Date;
  end: Date;
}

export function splitDateRangeIntoDailyGroups(startDate: Date, endDate: Date): DateRange[] {
  const groups: DateRange[] = [];
  let currentStart = startDate;

  while (isBefore(currentStart, endDate) || isSameDay(currentStart, endDate)) {
    // Define the end of the current day at 23:59:59
    const endOfDay = setHours(setMinutes(setSeconds(currentStart, 59), 59), 23);

    // Determine the end time for the current day's group
    const groupEnd = isBefore(endOfDay, endDate) ? endOfDay : endDate;

    // Only add a new group if currentStart is strictly before endDate or it ends exactly at the end of the previous day
    if (isBefore(currentStart, endDate) || isSameDay(currentStart, endDate)) {
      // Exclude endDate from creating a group if it's exactly midnight
      if (!(isEqual(groupEnd, endDate) && groupEnd.getHours() === 0 && groupEnd.getMinutes() === 0)) {
        groups.push({
          date: format(utcToZonedTime(currentStart, 'UTC'), 'yyyy-MM-dd'),
          start: currentStart,
          end: groupEnd,
        });
      }
    }

    // Move to the next day starting at 00:00:00
    currentStart = setHours(setMinutes(setSeconds(addDays(currentStart, 1), 0), 0), 0);
  }

  return groups;
}

export interface OutOfOfficeRequest {
    user: number;
    start_datetime: string;
    end_datetime: string;
    type: string;
    remark: string;
    appointment_date: string;
    room: null;
    table: null;
}