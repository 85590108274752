import React, { createContext, useContext, ReactNode } from 'react';

// Create a context for patientId
const PatientIdContext = createContext<number | undefined>(undefined);

// Create a provider component
interface PatientIdProviderProps {
  patientId: number;
  children: ReactNode;
}

export const PatientIdProvider: React.FC<PatientIdProviderProps> = ({ patientId, children }) => {
  return (
    <PatientIdContext.Provider value={patientId}>
      {children}
    </PatientIdContext.Provider>
  );
};

// Create a custom hook to use the patientId context
export const usePatientId = () => {
  const context = useContext(PatientIdContext);
  if (context === undefined) {
    throw new Error('usePatientId must be used within a PatientIdProvider');
  }
  return context;
};
