// Import the Patientv2 interface
import React from 'react';
import { Grid } from '@mui/material';
import { Patientv2 } from '../../../types';
import { getDateInDDMMYYYY } from '../../../utils/commonUtils';

interface PatientDetailsProps {
  selectedPatientDetails: Patientv2; // Use Patientv2 interface
}

const PatientDetails: React.FC<PatientDetailsProps> = ({ selectedPatientDetails }) => (
  <>
    <Grid container spacing={2} my={1} mx={1}>
      <Grid item xs={3}>
        <strong>First Name</strong>
        <div>{selectedPatientDetails.first_name}</div>
      </Grid>

      <Grid item xs={3}>
        <strong>Last Name</strong>
        <div>{selectedPatientDetails.last_name}</div>
      </Grid>

      <Grid item xs={3}>
        <strong>DOB</strong>
        <div>{getDateInDDMMYYYY(selectedPatientDetails.date_of_birth)}</div>
      </Grid>

      <Grid item xs={3}>
        <strong>Sex Assigned At Birth</strong>
        <div>{selectedPatientDetails.gender}</div>
      </Grid>

      <Grid item xs={3}>
        <strong>MRN</strong>
        <div>{selectedPatientDetails.medical_record_no || 'N/A'}</div> {/* Display 'N/A' if null */}
      </Grid>

      <Grid item xs={3}>
        <strong>Mobile Number</strong>
        <div>{selectedPatientDetails.mobile_number}</div>
      </Grid>

      <Grid item xs={3}>
        <strong>Email ID</strong>
        <div>{selectedPatientDetails.additional_info?.alternate_email || ''}</div>
      </Grid>
    </Grid>

    {selectedPatientDetails.partner && ( // Check if partner details exist
      <Grid container spacing={2} my={1} mx={1}>
        <Grid item xs={3}>
          <strong>Partner First Name:</strong>
          <div>{selectedPatientDetails.partner.first_name}</div>
        </Grid>

        <Grid item xs={3}>
          <strong>Partner Last Name:</strong>
          <div>{selectedPatientDetails.partner.last_name}</div>
        </Grid>

        <Grid item xs={3}>
          <strong>Partner DOB:</strong>
          <div>{selectedPatientDetails.partner.date_of_birth}</div>
        </Grid>

        <Grid item xs={3}>
          <strong>Sex Assigned At Birth:</strong>
          <div>{selectedPatientDetails.partner.gender}</div>
        </Grid>
      </Grid>
    )}
  </>
);

export default PatientDetails;
