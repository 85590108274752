import React, { useEffect, useState } from 'react';
import { Box, Chip, Divider, Grid, Modal, Stack, Typography } from '@mui/material';
import closeIcon from '../../assets/SVG/close-circle-event.svg';
import registerUserIcon from '../../assets/SVG/register-user.svg';
import rescheduleIcon from '../../assets/SVG/Reschedule.svg';
import cancelIcon from '../../assets/SVG/Cancel.svg';
import checkInIcon from '../../assets/SVG/Check in.svg';
import cancelAlertIcon from '../../assets/SVG/Cancel (1).svg';
import checkOutIcon from '../../assets/SVG/tag-cross.svg';
import { Appointment, AppointmentStatus, VisitLog } from '../../types';
import { getAppointmentById, getVisitLogs, updateAppointmentStatus } from '../../services/apiService';
import { convertISOToTime, convertToLocalDateTime, formatDateToDDMMYYYY } from '../../services/time';

import { useLoader } from '../loaderProvider/loader';
import Rescheduled from '../appointmentRescheduleModal';
import Cancel from '../appointmentCancelModal';
import DefaultLoader from '../defaultLoader';
import { useNavigate } from 'react-router-dom';
import { checkDatePassed } from '../../utils/commonUtils';

type ICustomModal = {
  id: number;
  isOpen: boolean;
  onClose: (value: boolean) => void;
}

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 'auto',
  height: 'auto',
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 3,
  borderRadius: '20px'
};


const CustomModal: React.FC<ICustomModal> = ({ id, isOpen, onClose }) => {

  const [appointment, setAppointment] = useState<Appointment | undefined>();
  const { showLoader, hideLoader } = useLoader();
  const [rescheduled, setRescheduled] = useState<boolean>(false);
  const [cancel, setCancel] = useState<boolean>(false);
  const [statusUpdated, setStatusUpdated] = useState(false);
  const [previousVisits, setPreviousVisits] = useState<Array<VisitLog>>([]);
  const navigate = useNavigate();

  useEffect(() => {
    showLoader();
    if (id) {
      getAppointmentById(id)
        .then((appointment: Appointment) => {
          return setAppointment(appointment);
        })
        .finally(() => hideLoader());
    }
  }, [id, hideLoader, showLoader, statusUpdated]);
    
  const renderDetail = (label: string, value: string | undefined) => (  
    <Grid item xs={12} sm={6} md={4} justifyContent='space-between'>
      <Typography fontFamily='Nunito' fontWeight={400} fontSize='14px' color='#9E9E9E'>
        {label}
      </Typography>
      <Typography color='#232323' fontFamily='Nunito' textTransform={(label === 'Email ID' || label === 'Time') ? 'lowercase': 'capitalize'} fontWeight={500} fontSize='16px'>
        {value ?? 'NA'}
        {/* <span>
          {appointment?.remark
            ? appointment.remark.length > 10
              ? `${appointment.remark.slice(0, 10)}...`
              : appointment.remark
            : ''}
        </span> */}

      </Typography>
    </Grid>
  );
  const chipValue = appointment?.status;
  const chipTextColor = chipValue === AppointmentStatus.COMPLETED ? '#47B35F' : chipValue === AppointmentStatus.UNATTENDED ? '#F7BD8E' : chipValue === AppointmentStatus.CHECKED_IN ? '#3A80E2' : 'gray';
  const chipColor = chipValue === AppointmentStatus.COMPLETED ? 'success' : chipValue === AppointmentStatus.UNATTENDED ? 'warning' : chipValue === AppointmentStatus.CHECKED_IN ? 'info' : 'primary';
  const chipBgColor = chipValue === AppointmentStatus.COMPLETED ? '#F4FFF6' : chipValue === AppointmentStatus.UNATTENDED ? '#FFF8F3' : chipValue === AppointmentStatus.CHECKED_IN ? '#F5F9FF' : '#ffffff';

  const checkInTime = appointment?.checked_in_time? convertToLocalDateTime(appointment?.checked_in_time): undefined;
  const checkOutTime = appointment?.checked_out_time? convertToLocalDateTime(appointment?.checked_out_time): undefined;

  const handleModalClose = () => {
    setAppointment(undefined);
    onClose(false);
    setRescheduled(false);
    setCancel(false);
  };

  const updateStatus = (status: string) => {
    const data = appointment;

    updateAppointmentStatus(data?.id || 0, status)
      .then((response) => {
      // eslint-disable-next-line no-console
        console.log(response);
        setStatusUpdated(!statusUpdated);
      })
      .catch((error) => {
      // eslint-disable-next-line no-console
        console.error('Error updating status:', error);
      });
  };

  useEffect(() => { 
    if (appointment?.patient?.id) {
      try {
        const query = 'order_by=created_on'; 

        getVisitLogs(appointment?.patient?.id, query)
          .then((logs) => {
            setPreviousVisits(logs.objects);
          })
          .catch((error) => {
            // eslint-disable-next-line no-console
            console.error('Error fetching visit logs:', error);
          });
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error('Unexpected error:', error);
      }
    }
  }, [appointment?.patient?.id]);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const isNewPatient = !previousVisits?.some((item: { [key: string]: any }) => {
    const appointmentDate = new Date(item.appointment_date).getTime();
    const today = new Date().setHours(0, 0, 0, 0);
    return appointmentDate < today;
  });

  
  const isPast = checkDatePassed(appointment?.appointment_date || '');
  const isToday = appointment?.appointment_date === new Date().toISOString().split('T')[0];
    
  if (appointment){
    return (
      <Modal open={isOpen} onClose={onClose}>
        <Box sx={style}>
  
          {
            (!rescheduled && !cancel) ?
              <Stack direction='row' justifyContent='space-between'>
                <Typography color='#232323' fontSize='20px' fontWeight={700}>Patient Information</Typography>
                <Stack direction='row' gap={3}>
                  <Chip label={chipValue?.replace('_', ' ')} variant='outlined' sx={{ color: chipTextColor, fontWeight: 700, background: chipBgColor }} color={chipColor} />
                  <img src={closeIcon} style={{ cursor: 'pointer' }} onClick={handleModalClose} width={28} alt='close-icon' />
                </Stack>
              </Stack> :  
  
              // Rescheduled / Cancel Heading
              <Stack direction='row' alignItems='center' justifyContent='space-between'>
                <div style={{ display: 'flex', alignItems: 'center', gap: 12 }}>
                  <div>
                    <img src={cancelAlertIcon} alt='cancel-icon' />
                  </div>
  
                  <div>
                    <Typography fontFamily='Montserrat' fontWeight={600} fontSize='20px' color='#232323'>
                      {rescheduled ? 'Reschedule Appointment ?' : 'Cancel Appointment ?'}
                    </Typography>
                    <Typography fontFamily='Nunito' fontWeight={400} fontSize='14px' color='#404653'>
                      {rescheduled ? 'Are you sure you want to reschedule this appointment ?' : 'Are you sure you want to cancel this appointment ?'}
                    </Typography>
                  </div>
                </div>
                <img src={closeIcon} style={{ cursor: 'pointer' }} onClick={handleModalClose} width={28} alt='close-icon' />
              </Stack>
          }
  
  
          {
            rescheduled ?
              <Rescheduled data={appointment} onClose={handleModalClose} /> : 
              cancel ? 
                <Cancel data={appointment} onClose={handleModalClose} /> : 
                <>
                  <Grid container spacing={3} mt={2}>
                    {renderDetail('First Name', appointment?.patient?.first_name)}
                    {renderDetail('Last Name', appointment?.patient?.last_name)}
                    {renderDetail('Sex Assigned At Birth', appointment?.patient?.gender)}
                    {appointment?.patient?.mobile_number_country_code ? renderDetail('Mobile No.', `${appointment.patient.mobile_number_country_code}-${appointment.patient.mobile_number}`): renderDetail('Mobile No.', `${appointment?.patient?.mobile_number}`)}
                    {renderDetail('Date Of Birth', formatDateToDDMMYYYY(appointment?.patient?.date_of_birth || ''))}
                    {renderDetail('Email ID', appointment?.patient?.additional_info?.alternate_email || 'N/A')}
                    {renderDetail('Department', appointment?.department__name)}
                    {renderDetail(appointment?.department__name === 'Admin' ? 'Personnel' : 
                      appointment?.department__name === 'Pathology' ? 'Pathologist' : 'Doctor Name', 
                    `${appointment?.department__name !== 'Admin' ? 'Dr. ' : ''}
                      ${appointment?.user?.first_name ?? ''} ${appointment?.user?.last_name ?? ''}`.trim()
                    )}

                    {renderDetail('Appointment Reason', appointment?.appointment_reason__reason)}
                    {renderDetail('Date', formatDateToDDMMYYYY(appointment?.appointment_date || ''))}
                    {renderDetail('Time', `${convertISOToTime(appointment?.start_datetime || '', true)} - ${convertISOToTime(appointment?.end_datetime || '', true)}`)}
                    {renderDetail('Remark', appointment?.remark
                      ? appointment?.remark?.length > 20
                        ? `${appointment.remark.slice(0, 30)}...`
                        : appointment?.remark
                      : 'N/A')}
                  </Grid>
  
                 
                  { (checkInTime || !isPast) && <Divider style={{ height: '1px', background: '#595959', marginTop: 20, marginBottom: 20 }} />}
                  { (!appointment?.patient?.additional_info?.ethnicity?.length && !appointment?.checked_in_time && !isPast && isToday) && <Typography color='warning'>Note: Registration form must be completed before check-in</Typography>}
                  {(checkInTime && (appointment?.status === AppointmentStatus.CHECKED_IN || appointment?.status === AppointmentStatus.CHECKED_OUT)) && <Stack direction='row' gap={4}>
                    {checkInTime && <div style={{ display: 'flex', gap: 5 }}>
                      <Typography color='#9E9E9E' fontSize='14px' fontWeight={400}>Check-In :</Typography>
                      <Typography color='#232323' fontSize='14px' fontWeight={600}>{convertISOToTime(checkInTime.toString(), true)}</Typography>
                    </div>}
  
                    {checkOutTime && (appointment?.status === AppointmentStatus.CHECKED_OUT) && <div style={{ display: 'flex', gap: 5 }}>
                      <Typography color='#9E9E9E' fontSize='14px' fontWeight={400}>Check-Out :</Typography>
                      <Typography color='#232323' fontSize='14px' fontWeight={600}>{convertISOToTime(checkOutTime.toString(), true)}</Typography>
                    </div>}
                  </Stack>}
                  {/* Checking if the appointment date has passed or not */}
                  {
                    !isPast ? 
                      <>
                        <Stack direction='row' gap={6} mt={appointment?.status === AppointmentStatus.CHECKED_OUT ? 0 : 3}>
                    
                          {((!checkInTime && isNewPatient && !appointment?.patient?.additional_info?.ethnicity?.length) && 
                          <div style={{ display: 'flex', gap: 5, cursor: 'pointer', alignItems: 'center' }} 
                            onClick={() => navigate('/admin/registration/patient-partner-registration', { state: appointment?.patient })}>
                            <img src={registerUserIcon} width={22} alt='register-icon' />
                            <Typography>Register</Typography>
                          </div>
                          )}


                          {
                        
                            (appointment?.status === AppointmentStatus.SCHEDULED &&
                        
                              isToday && 
                              <div style={{ display: 'flex', gap: 5, cursor: appointment?.patient?.additional_info?.ethnicity?.length ? 'pointer' : 'not-drop' }}>
                                <div
                                  onClick={() => appointment?.patient?.additional_info?.ethnicity?.length && updateStatus(AppointmentStatus.CHECKED_IN)} // Adjust according to enum value
                                  style={{ display: 'flex', alignItems: 'center', gap: '5px', 
                                    pointerEvents: appointment?.patient?.additional_info?.ethnicity?.length ? 'auto' : 'none'  }}
                                >
                                  <img src={checkInIcon} alt='checkIn-icon' />
                                  <Typography color={appointment?.patient?.additional_info?.ethnicity?.length ? '#000' : '#c1c1c1'}>Check - In</Typography>
                                </div>
                              </div>
                        
                            )
  
                          }
                    
  
                          { chipValue !== AppointmentStatus.CHECKED_OUT ?
                            <>
                              <div style={{ display: 'flex', gap: 5, cursor: 'pointer', alignItems: 'center' }} onClick={() => setRescheduled(true)}>
                                <img src={rescheduleIcon} width={22} alt='register-icon' />
                                <Typography>Reschedule</Typography>
                              </div>
  
                              <div style={{ display: 'flex', gap: 5, cursor: 'pointer', alignItems: 'center' }} onClick={() => setCancel(true)}>
                                <img src={cancelIcon} width={22} alt='cancel-user' />
                                <Typography >Cancel</Typography>
                              </div>
                            </> : null
                          }
  
                          { (chipValue === AppointmentStatus.CHECKED_IN && isToday) &&
                            <div style={{ display: 'flex', gap: 5, cursor: 'pointer', alignItems: 'center' }} onClick={() => updateStatus('CHECKED_OUT')}>
                              <img src={checkOutIcon} alt='checkOut-icon' />
                              <Typography>Check - Out</Typography>
                            </div>
                          }
  
                        </Stack>                    
                      </> : null
                  }
                 


                </> 
          }
  
        </Box>
      </Modal>
    );
  } else {
    return <DefaultLoader/>;
  }
};

export default CustomModal;